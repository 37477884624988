import { parsedEnv } from "../../utils/parsedEnv";
import { firebaseAuthService } from "../services/auth/implementations/firebase";
import { assignHeaders, BadResponseError, Client } from "../../modules/client/client";
import { string, type } from "superstruct";
import { isAuthenticated } from "../../modules/auth/authContext";

export const publicAuthClient = new Client(parsedEnv.VITE_SERVER_PEOPLE);

let paulsenTokens: {
    access: string;
    refresh: string;
} | null = null;

export const personClient = new Client(parsedEnv.VITE_SERVER_PEOPLE, {
    intercept: async (request, fetchData) => {
        if (!isAuthenticated()) return fetchData(request);

        // Convert the firebase token into paulsen tokens if we haven't done so yet
        if (!paulsenTokens) {
            paulsenTokens = await exchangeTokens(await firebaseAuthService.getIdToken());
        }
        try {
            // Happy path: we make the request using our stored paulsen access token
            assignHeaders(request.headers, { Authorization: `Bearer ${paulsenTokens.access}` });
            return await fetchData(request);
        } catch (accessError) {
            if (accessError instanceof BadResponseError && accessError.response.status === 401) {
                // Sad path: 401 means our paulsen access token is probably expired
                try {
                    /* Recover attempt 1: use the refresh token endpoint as it's more performant
                     * than exchanging the firebase token again. */
                    paulsenTokens.access = await refreshToken(paulsenTokens);
                    assignHeaders(request.headers, {
                        Authorization: `Bearer ${paulsenTokens.access}`,
                    });
                    return await fetchData(request);
                } catch (refreshError) {
                    if (
                        refreshError instanceof BadResponseError &&
                        refreshError.response.status === 401
                    ) {
                        /* Recover attempt 2: Ugh, so the refresh token is also expired?
                         * Maybe we can still exchange the firebase token the slow way. */
                        paulsenTokens = await exchangeTokens(
                            await firebaseAuthService.getIdToken(),
                        );
                        assignHeaders(request.headers, {
                            Authorization: `Bearer ${paulsenTokens.access}`,
                        });
                        // No more nested try catch, if this fails, all hope is lost.
                        return await fetchData(request);
                    } else throw refreshError;
                }
            } else throw accessError;
        }
    },
});

async function exchangeTokens(firebaseIdToken: string): Promise<{
    access: string;
    refresh: string;
}> {
    return publicAuthClient
        .post("/token/")
        .sendJson({ firebase_token: firebaseIdToken })
        .receive(type({ access: string(), refresh: string() }));
}

async function refreshToken(paulsenTokens: { access: string; refresh: string }): Promise<string> {
    return publicAuthClient
        .post("/token/refresh/")
        .sendJson({ refresh: paulsenTokens.refresh })
        .receive(type({ access: string() }))
        .then(payload => payload.access);
}

export function paulsenSignOut(): void {
    paulsenTokens = null;
}
