import { createSignal, JSX, mergeProps, onCleanup, onMount, ParentProps, Show } from "solid-js";
import { createRef, MutableRefObject } from "../../utils/reactRefs";

export default function Dropdown(
    props: ParentProps<{
        trigger: (onClick: () => void) => JSX.Element;
        padding?: "default" | "none";
        itemsContainerClass?: string;
    }>,
) {
    const merged = mergeProps({ padding: "default" }, props);
    const [isOpen, setIsOpen] = createSignal(false);
    const triggerRef = createRef<HTMLElement>();
    const dropdownRef = createRef<HTMLDivElement>();

    useOnClickOutside(dropdownRef, event => {
        // to avoid the dropdown reopening immediately after closing it
        if (triggerRef.current!.contains(event.target as Node)) return;

        setIsOpen(false);
    });

    return (
        <div class="relative">
            <div ref={triggerRef}>{props.trigger(() => setIsOpen(x => !x))}</div>
            <Show when={isOpen()}>
                <div
                    ref={dropdownRef}
                    class={`absolute right-0 top-full z-dropdown ${props.itemsContainerClass}`}
                    classList={{ "pt-2": merged.padding === "default" }}
                    onClick={event => {
                        event.stopPropagation();
                        setIsOpen(false);
                    }}
                >
                    {props.children}
                </div>
            </Show>
        </div>
    );
}

export function useOnClickOutside<THTMLElement extends HTMLElement = HTMLElement>(
    ref: MutableRefObject<THTMLElement>,
    handler: (event: MouseEvent | TouchEvent) => void,
) {
    onMount(() => {
        document.addEventListener("mousedown", handleClick);
        document.addEventListener("touchstart", handleClick);
    });

    onCleanup(() => {
        document.removeEventListener("mousedown", handleClick);
        document.removeEventListener("touchstart", handleClick);
    });

    function handleClick(event: MouseEvent | TouchEvent) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            handler(event);
        }
    }
}
