import { CalendarControls, Week, WeekColumns } from "../ui/calendar";
import { createUserActivitiesSliceQuery } from "../../api/services/task-manager/queries";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { JsonDebug } from "../../utils/debug";
import { createSignal, Show, Suspense } from "solid-js";
import { formattedDate } from "../../utils/formatDate";

export default function WeeklyActivities() {
    const [week, setWeek] = createSignal(Week.now());
    const userActivitiesSliceQuery = createUserActivitiesSliceQuery(() => ({
        startDate: week().toPlainDate({ dayOfWeek: 1 }),
        endDate: week().toPlainDate({ dayOfWeek: 7 }),
    }));

    return (
        <div>
            <div class="flex items-baseline gap-4">
                <CalendarControls
                    onPrev={() => setWeek(w => w.subtract({ weeks: 1 }))}
                    onNext={() => setWeek(w => w.add({ weeks: 1 }))}
                    date={week().toPlainDate({ dayOfWeek: 1 })}
                    onChangeDate={d => setWeek(Week.fromPlainDate(d))}
                    onToday={() => setWeek(Week.now())}
                />
                <div>
                    {formattedDate(week().toPlainDate({ dayOfWeek: 1 }))} &ndash;{" "}
                    {formattedDate(week().toPlainDate({ dayOfWeek: 7 }))}
                </div>
            </div>
            <Suspense fallback={<GenericSuspenseFallback />}>
                <Show when={userActivitiesSliceQuery.data}>
                    {activities => (
                        <WeekColumns week={week()}>
                            {date => (
                                <div>
                                    <JsonDebug
                                        value={activities().filter(act =>
                                            act.dueDate.toPlainDate().equals(date),
                                        )}
                                    />
                                </div>
                            )}
                        </WeekColumns>
                    )}
                </Show>
            </Suspense>
        </div>
    );
}
