import Toastify from "toastify-js";
import { createNewPersonMutation } from "../../api/services/person/mutations";
import { EmailAlreadyInUseError } from "../client/client";
import { FormWrapper } from "../forms/FormWrapper";
import SubmitButton from "../forms/SubmitButton";
import { createForm } from "../forms/state";
import { useLocale } from "../i18n/context";
import { ModalController } from "../ui/Modal";
import SidePanel from "../ui/SidePanel";
import { LavandaCard } from "../ui/cards";
import { Button } from "../ui/components";
import {
    PersonalDataWithOrganizationFormValues,
    OrganizationInvitationFields,
} from "./OrganizationInvitationForm";
import { PersonalDataFields } from "./PersonalDataForm";
import { P } from "../../utils/typography";

export function CreatePersonSidePanel(props: { controller: ModalController<void, void> }) {
    const [locale] = useLocale();
    const form = createForm<PersonalDataWithOrganizationFormValues>();
    const emailField = form.getFieldController("email");
    const createPersonMutation = createNewPersonMutation();
    const handleSubmit = async (formValues: PersonalDataWithOrganizationFormValues) => {
        try {
            await createPersonMutation.mutateAsync({
                email: formValues.email,
                name: formValues.name,
                identityDocuments: formValues.identityDocuments,
                phones: formValues.phone ? [formValues.phone] : [],
                pictureUrl: "",
                send_invitation: true,
            });
            props.controller.dismiss();
            form.reset();
            Toastify({
                text: locale().personalData.personCreated,
            }).showToast();
        } catch (e) {
            if (e instanceof EmailAlreadyInUseError) {
                emailField.setBackendErrorMessage(locale().personalData.emailAlreadyInUse);
            } else {
                throw e;
            }
        }
    };
    return (
        <SidePanel controller={props.controller} position="right">
            <LavandaCard class={"relative z-side-panel sm:!px-4"}>
                <FormWrapper<PersonalDataWithOrganizationFormValues>
                    onSubmit={handleSubmit}
                    staticForm={form}
                >
                    <div>
                        <P class={"text-xl font-medium"}>Crear persona</P>
                        <div class={"flex flex-col items-center gap-4"}>
                            <PersonalDataFields readonlyEmail={false} />
                            <OrganizationInvitationFields />
                            <div class="flex w-full justify-end gap-x-3 border-t border-light-gray-300 pt-3">
                                <Button
                                    onClick={() => props.controller.dismiss()}
                                    variant="primary"
                                    bgStyle="outline"
                                >
                                    Cancelar
                                </Button>
                                <SubmitButton submittingText={locale().personalData.savingChanges}>
                                    {locale().personalData.saveChanges}
                                </SubmitButton>
                            </div>
                        </div>
                    </div>
                </FormWrapper>
            </LavandaCard>
        </SidePanel>
    );
}
