import { useNavigate } from "@solidjs/router";
import { Person } from "../../api/services/person/interface";
import { P } from "../../utils/typography";
import { Button, Icon } from "../ui/components";
import { InfoBubble } from "../ui/InfoBubble";

export function UserListCard(props: { person: Person }) {
    const navigate = useNavigate();
    const onClickDetail = () => {
        navigate(`/people/${props.person.id}`);
    };

    const onClickEdit = () => {
        alert("Edit user WIP");
    };

    const onClickDelete = () => {
        alert("Delete user WIP");
    };
    return (
        <div class="first:pt-0 grid grid-cols-12 gap-x-3 px-2 py-2">
            <div class="col-span-4 flex items-center gap-x-3">
                <Icon name="fas fa-user-check" />
                <div>
                    <P class={"!mb-1"}>{props.person.name}</P>
                    <P class={"!mb-0 rounded-full bg-light-gray-200 px-2 py-1 text-xs"}>
                        {props.person.email}
                    </P>
                </div>
            </div>

            <div class="col-span-3">
                <RoleInfo />
            </div>

            <div class="col-span-3 flex items-center">
                <div class={"flex flex-wrap gap-1"}>
                    <InfoBubble text="Jefes zonales" />
                    <InfoBubble text="Gerente Comercial" />
                </div>
            </div>
            <div class="col-span-2 flex items-center justify-center">
                <div class={"flex gap-x-3 rounded-md border border-dark-gray-400 p-2"}>
                    <Button
                        variant="primary"
                        bgStyle="outline"
                        class={"!p-0 !border-none"}
                        onClick={onClickDetail}
                    >
                        <Icon name="fas fa-eye text-dark-gray-400" />
                    </Button>
                    <Button
                        variant="primary"
                        bgStyle="outline"
                        class={"!p-0 !border-none"}
                        onClick={onClickEdit}
                    >
                        <Icon name="fas fa-pen text-dark-gray-400" />
                    </Button>
                    <Button
                        variant="primary"
                        bgStyle="outline"
                        class={"!p-0 !border-none"}
                        onClick={onClickDelete}
                    >
                        <Icon name="fas fa-trash text-error-500" />
                    </Button>
                </div>
            </div>
        </div>
    );
}

function RoleInfo() {
    return (
        <div>
            <P class={"!mb-1"}>Gerente Comercial</P>
            <div class="flex items-center gap-x-2">
                <div>
                    <Icon name="fas fa-building text-dark-gray-400" />
                </div>
                <div class={"flex gap-1"}>
                    <InfoBubble text="Plaza Santiago Norte" />
                </div>
            </div>
        </div>
    );
}
