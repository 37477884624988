import { CreatePerson, Person, PersonService, UserProfile } from "../interface";
import MockPersonService from "./mock";
import { BadResponseError, EmailAlreadyInUseError } from "../../../../modules/client/client";
import { any, array, create, integer, nullable, optional, string, type } from "superstruct";
import { personClient } from "../../../clients/paulsen";

export default class V3PersonService implements PersonService {
    mock = new MockPersonService();

    retrieveCurrentUserProfile = async (): Promise<UserProfile> => {
        const data = await personClient.get("/who_am_i").receive(
            type({
                email: string(),
            }),
        );
        console.debug({ data });
        return { ...(await this.mock.retrieveCurrentUserProfile()), ...data };
    };

    retrieveIdentityOptions = this.mock.retrieveIdentityOptions;
    retrievePersonalData = this.mock.retrievePersonalData;
    updatePersonalData = this.mock.updatePersonalData;

    getPeople = async (): Promise<Person[]> => {
        const profiles = await personClient.get("/profiles/").receive(array(sV3Profile()));
        return profiles.map(profile => ({
            id: profile.id,
            name: `${profile.first_name} ${profile.last_name}`,
            email: profile.email,
            phones: profile.phone ? (profile.phone.length > 0 ? [profile.phone] : []) : [],
            pictureUrl: null,
            identityDocuments: [],
        }));
    };

    async getPerson(id: number): Promise<Person> {
        const persons = await this.getPeople();

        const person = persons.find(p => p.id == id);
        if (person) {
            return Promise.resolve(person);
        }
        return Promise.reject(new Error("Person not found"));
    }

    createPerson = async (person: CreatePerson): Promise<void> => {
        try {
            await personClient
                .post("/profiles/")
                .sendJson({
                    send_invitation: person.send_invitation,
                    email: person.email,
                    first_name: person.name,
                    last_name: "",
                    phone: person.phones.length > 0 ? person.phones[0] : null,
                })
                .receive(any());
        } catch (e) {
            if (e instanceof BadResponseError) {
                const body = create(e.body, type({ email: optional(array(string())) }));
                if (body.email && Array.isArray(body.email) && body.email.length > 0) {
                    if (body.email[0] === "profile with this email already exists.") {
                        throw new EmailAlreadyInUseError();
                    }
                }
                console.log("BadResponseError", e.body);
            }
            throw e;
        }
    };
}

function sV3Profile() {
    return type({
        id: integer(),
        first_name: string(),
        last_name: string(),
        email: nullable(string()),
        phone: optional(string()),
        user: nullable(integer()),
    });
}

export function sV3UserProfile() {
    return type({
        last_name: string(),
        first_name: string(),
        email: string(),
        pictureUrl: nullable(string()),
    });
}

export function sV3WorkflowProfile() {
    return type({
        id: integer(),
        last_name: string(),
        first_name: string(),
        email: string(),
        username: string(),
    });
}
