import { nonCheckboxInputStyling } from "../presentation";
import { ValidationIconWrapper } from "./parts/icons";
import { LabelAbove } from "./parts/layouts";
import { getCommonInputProps, getInputValidationProps } from "./parts/props";
import { createField } from "../state";
import { FieldProps } from "./parts/types";
import { createUniqueId } from "solid-js";

export default function TextAreaField(props: FieldProps<string, HTMLTextAreaElement>) {
    const field = createField(props, "");
    const inputId = createUniqueId();

    return (
        <LabelAbove {...props} field={field} inputId={inputId}>
            <ValidationIconWrapper field={field}>
                {/* We may want an "autogrow" textarea later, when the time arrives,
                 * see https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/ */}
                <textarea
                    {...getCommonInputProps(inputId, props)}
                    {...getInputValidationProps(field, props)}
                    {...nonCheckboxInputStyling}
                    value={field.value}
                />
            </ValidationIconWrapper>
        </LabelAbove>
    );
}
