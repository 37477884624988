import Avatar from "../auth/Avatar";
import { Button } from "../ui/components";
import LogoutButton from "../auth/LogoutButton";
import { SettingsButton } from "../settings/SettingsTab";
import { useLocale } from "../i18n/context";
import { useNavigate } from "@solidjs/router";
import { createResource, ErrorBoundary, Show, Suspense } from "solid-js";
import { getApiInstance } from "../../api";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { ErrorBlock } from "../../utils/GenericErrorBoundary";

export default function ProfileCardContent() {
    return (
        <Suspense fallback={<GenericSuspenseFallback />}>
            <div class="flex flex-col">
                <ErrorBoundary fallback={error => <ErrorBlock error={error} />}>
                    <div class="flex flex-col border-b border-b-light-gray-300 p-3">
                        <ProfileOverview />
                        <div class="self-center">
                            <EditProfileButton />
                        </div>
                    </div>
                </ErrorBoundary>
                <div class="flex">
                    <SettingsButton />
                    <div class="flex-1" />
                    <LogoutButton />
                </div>
            </div>
        </Suspense>
    );
}

function ProfileOverview() {
    const api = getApiInstance();
    const [profile] = createResource(api.person.retrieveCurrentUserProfile);

    return (
        <Show when={profile()}>
            {profile => (
                <div>
                    <div class="mb-3 flex items-center gap-3">
                        <Avatar picture={profile().pictureUrl} size="lg" />
                        <div>
                            <div class="text-md leading-tight text-light-gray-700">
                                {profile().name}
                            </div>
                            <div class="text-default text-dark-gray-400">{profile().email}</div>
                        </div>
                    </div>
                </div>
            )}
        </Show>
    );
}

function EditProfileButton() {
    const [locale] = useLocale();
    const navigate = useNavigate();

    return (
        <Button bgStyle="outline" size="sm" class="w-80" onClick={() => navigate("/profile/edit")}>
            {locale().personalData.editProfile}
        </Button>
    );
}
