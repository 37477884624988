import { Locale } from "../Locale";
import { WorkflowCompletionStatus } from "../../../api/services/workflow/interface";
import { capitalize } from "lodash";

export const es: Locale = {
    name: "Español",
    codeWithCountry: "es-CL",

    activities: {
        noActivitiesAtAll: "No tienes actividades, ¡hurra!",
        noActivitiesThisDay: "No tienes actividades este día.",
        showAvailable: "Mostrar disponibles",
    },

    auth: {
        welcome: "Bienvenido",
        welcomeTo: "Bienvenid@ a",
        organizationUrl: "Ingresa la URL de tu organización",
        forgotOrganizationUrl: "¿No sabes la URL de tu organización?",
        findYourOrganization: "Encuentra tu organización",
        letsFindYourOrganization: "Encontremos tu organización",
        weSuggestUseYourEnterpriseEmail:
            "Te sugerimos utilizar la dirección de correo que ocupas en tu trabajo.",
        continueWithEmail: "Continuar con Email",
        continuingWithEmail: "Continuando con Email...",
        wrongMagicCode: "Código incorrecto. Por favor, vuelve a intentarlo.",
        youAreNotInOrganization: (company: string) => `No estás registrado en ${company}`,
        workspaceStepPlease:
            "Por favor, selecciona en cuál de tus espacios de trabajo iniciar sesión.",
        youCanChangeItLater: "Podrás cambiarlo más tarde desde la barra de navegación.",
        noWorkspaces1: "Disculpa, no hemos encontrado espacios de trabajo asociados a",
        noWorkspaces2: "Revisa que esté bien escrito o intenta con otro correo.",
        loginWithGoogle: "Iniciar sesión con Google",
        or: "Ó",
        email: "Correo electrónico",
        emailPlaceholder: "tu.nombre@empresa.com",
        continueWithMagicLink: "Continuar con vínculo mágico",
        continuingWithMagicLink: "Continuando con vínculo mágico...",
        magicLinkParagraph:
            "Al utilizar el vínculo mágico, te enviaremos por email un vínculo para iniciar sesión sin contraseña.",
        continueWithPassword: "Continuar con contraseña",
        password: "Contraseña",
        forgotPassword: "¿Olvidaste tu contraseña?",
        passwordResetEmailSent:
            "Te hemos enviado un correo electrónico con instrucciones para restablecer tu contraseña.",
        signIn: "Iniciar sesión",
        signingIn: "Iniciando sesión...",
        passwordRequired: "Debes ingresar una contraseña",
        wrongPassword: "Contraseña incorrecta. Por favor, inténtalo nuevamente.",
        noSignInMethods1: "¡Vaya!, no hay métodos de inicio de sesión disponibles para ",
        noSignInMethods2: ".",
        notImplementedWidget1: "¡Vaya!, ",
        notImplementedWidget2: " te permite iniciar sesión a través de ",
        notImplementedWidget3:
            ", pero ese método de inicio de sesión aún no está disponible en esta versión de la app de AIM Manager.",
        sessionExpired: "Tu sesión ha expirado. Por favor, inicia sesión nuevamente.",
        createInvite: "Crear invitación",
        createInviteDescription: "Crea una invitación para un nuevo usuario.",
        sendEmail: "Enviar email",
        validateEmail: "Validar email",
        validatingEmail: "Validando email...",
        emailLinkSent: "Enlace de email enviado",
        continue: "Continuar",
        continuing: "Continuando...",
        myCompanyLink: (companyName: string) => `https://${companyName}.aimmanager.com`,
        invalidEmail: "Email inválido",
        checkYourEmail: "Revisa tu correo",
        weHaveSentACode_1: "Hemos enviado un código de 6 dígitos al correo",
        weHaveSentACode_2: "El código tiene expiración, te recomendamos utilizarlo pronto.",
        cantFindTheCode: "¿No puedes encontrar el código?",
        cantFindTheMagicLink: "¿No puedes encontrar el vínculo?",
        checkSpam: "Revisa tu carpeta de spam.",
        weHaveSentLinkToChangePassword:
            "Hemos enviado un vínculo para cambiar la contraseña al correo",
        weHaveSentMagicLink: "Hemos enviado un vínculo mágico para iniciar sesión al correo",
        signInWithMagicLink: "Inicia sesión con Vinculo Mágico",
        signingInWithMagicLink: "Iniciando sesión con Vinculo Mágico",
        resendEmail: "Reenviar correo",
        enterNewEmail: "Ingresa nuevo correo",
        contactSupport: "Contactar a soporte",
        people: "Personas",
        searchUser: "Buscar usuario",
        createPerson: "Crear persona",
        restartNeeded: organizationName =>
            `Por motivos técnicos, antes de iniciar sesión en ${organizationName} necesitamos que reinicies la app. Por favor, vuelve a abrir la app manualmente luego de cerrar este mensaje. Disculpa las molestias.`,
        exportPeopleToXLSX: "Exportar personas a XLSX",
        peopleMassiveLoad: "Carga masiva de personas",
        peopleSearchLabel: "Ingrese los datos de la persona que está buscando",
        organizationNotFound: organizationName =>
            `No encontramos la organización ${organizationName}.`,
        organizationNotFoundDescription: "Si el problema persiste, contacta con tu administrador.",
    },

    checklists: {
        noSections: "Este checklist no tiene secciones aún.",
        noSteps: "No hay pasos aún.",
        checklistExecutions: "Ejecuciones de checklist",
        noChecklistExecutions: "No hay ejecuciones de checklist aún.",
    },

    forms: {
        requiredError: "Este campo es requerido",
        select: "Seleccionar",
        optional: "opcional",
        withoutFields: "Este formulario no tiene campos aún.",
        simulateSubmit: "Simular envío",
        simulationResult: "Se enviarían las siguientes variables de proceso:",
    },

    menu: {
        pinnedTabs: {
            home: "Inicio",
            tools: "Herramientas",
            organization: "Organización",
            advanced: "Avanzado",
            myAccount: "Mi cuenta",
        },
        search: "Buscar",
        expanded: {
            supermarket: "Supermercado",
            training: "Capacitación",
            qaTesting: "Pruebas QA",
            complaints: "Reclamos",
            selfManagementOfPosts: "Autogestión de comunicados",
            myReports: "Mis reportes",
        },
        logout: "Cerrar sesión",
        version: "Versión",
        tabs: {
            home: "Inicio (maqueta)",
            posts: "Comunicados (maqueta)",
            activities: "Actividades (maqueta)",
            linksOfInterest: "Links de interés",
            reports: "Reportes",
            assets: "Activos",
            organization: "Organización (maqueta)",
            checklists: "Checklists",
            audiences: "Audiencias",
            workflows: "Workflows",
        },
        notifications: "Notificaciones",
        profile: "Perfil",
    },

    notifications: {
        title: "Notificaciones",
        noNotifications: "No hay notificaciones",
        newActivity: "Nueva actividad",
        newIncident: "Nuevo incidente",
        newPost: "Nuevo comunicado",
        newReport: "Nuevo reporte",
        systemUpdate: "Actualización del sistema",
        test: "Notificación de prueba",
        deleteNotification: "Eliminar notificación",
        noMoreNotifications: "No tienes más notificaciones aún",
        permissionDenied: "Parece que has denegado el permiso para las {notifications}.",
        optOutWarning: organizationName =>
            `AIM Manager respeta tu decisión, sin embargo, ${organizationName} podría tener una política de notificaciones requeridas para que estés al tanto de tareas y comunicados urgentes de la compañía.`,
        enableNotifications: "Habilitar {notifications}",
        nativeNotifications: "notificaciones push",
        webNotifications: "notificaciones de escritorio",
        markAsRead: "Marcar como leída",
        inboxNotificationsSseError:
            "Debido a un error no se te alertará de nuevas notificaciones dentro de la app.",
    },

    personalData: {
        welcomeToWorkspace: "Bienvenido al espacio de trabajo de {company} en AIM Manager",
        weCanHelpYou:
            "Te ayudamos a utilizar correctamente tu sesión en AIM Manager, con un completo equipo de on-boarding que se encargará de acompañarte en todo momento para solucionar dudas y hacer más fácil la puesta en marcha de la plataforma con capacitaciones guiadas y soporte especializado.",
        pleaseFillTheForm:
            "Por favor, llena los siguientes campos. Si no tienes tiempo ahora, puedes hacerlo en cualquier momento desde tu perfil de usuario.",
        name: "Nombre",
        roles: "Cargos",
        groups: "Grupos",
        identityDocumentType: "Tipo de documento",
        identityDocumentTypes: {
            CL_CI: "RUT chileno",
            PASSPORT: "Pasaporte",
            PE_DNI: "DNI peruano",
            CO_CC: "Cédula de ciudadanía colombiana",
            MX_CURP: "CURP mexicano",
            MX_RFS: "RFC mexicano",
            MX_INE: "INE mexicano",
            MX_NSS: "NSS mexicano",
            SV_DUI: "DUI salvadoreño",
        },
        identityDocumentNumber: "Número de documento",
        rutPlaceholder: "12345678-k (guión opcional)",
        invalidRut: "RUT inválido",
        addAnotherIdentityDocument: "Añadir otro documento de identidad",
        email: "Correo electrónico",
        phone: "Teléfono de contacto",
        addAnotherPhone: "Añadir otro teléfono de contacto",
        photo: "Foto",
        optional: "opcional",
        addFile: "Agregar archivo",
        submit: "Enviar",
        submitting: "Enviando...",
        notNow: "Omitir por ahora",
        editProfile: "Editar perfil",
        changeProfilePicture: "Cambiar imagen de perfil",
        saveChanges: "Guardar cambios",
        savingChanges: "Guardando cambios...",
        personCreated: "Persona creada",
        emailAlreadyInUse: "El correo electrónico ya está en uso",
        sendInvitation: "Enviar una invitación a la persona para ingresar a AIM Manager",
        invitationType: "Tipo de invitación",
        wherePersonBelongs: "¿Dónde pertenece la persona que está creando?",
        wherePersonBelongsOptions: {
            ownOrganization: (val: string) => `La persona pertenece a ${val} (Cuenta mandante)`,
            comercialPartner: "La persona pertenece a un socio comercial",
            contractorBusiness: "La persona pertenece a una empresa contratista",
        },
        selectWorkspace: "Seleccione el o las áreas de trabajo para la persona",
        selectRoles: "Seleccione el o los cargos para la persona",
        dontFindRole: "¿No encuentras el cargo que estás buscando? Puedes crear un cargo",
        tabs: {
            documentsTab: "Documentos",
            historyTab: "Historial",
            placesTab: "Recintos",
            profileTab: "Perfil",
            rolesTab: "Cargos",
        },
        administrationData: "Datos de administración",
        businessLine: "Líneas de negocio",
        searchWorkspace: "Buscar recinto",
        address: "Dirección",
        searchDocuments: "Buscar documentos",
        solicitNewDocument: "Solicitar tipo de documento a usuario",
        actions: "Acciones",
    },

    posts: {
        posts: "Comunicados",
        latestPosts: "Últimos comunicados",
        fromChannel: "del canal {channel}",
        goBack: "Volver",
        publishedIn: "Publicado en",
    },

    settings: {
        title: "Ajustes",
        language: "Idioma",
        simulateOnboarding: "Simular onboarding",
        createInvite: "Crear invitación",
        simulateError: "Simular error",
        simulatedError: "Este es un error simulado.",
    },

    sync: {
        title: "Sincronización",
    },

    taskManagerHome: {
        addPhoto: "Agregar foto",
        camera: {
            promptLabelHeader: "¿Cómo quieres obtener la foto?",
            promptLabelCancel: "Cancelar",
            promptLabelPhoto: {
                android: "Desde la galería",
                ios: "Desde la fototeca",
            },
            promptLabelPicture: "Tomar foto",
        },
        changePhoto: "Cambiar foto",
        uploadPhoto: "Subir foto",
    },

    utils: {
        goBack: "Volver",
        loading: "Cargando",
        monthsFull: [
            "enero",
            "febrero",
            "marzo",
            "abril",
            "mayo",
            "junio",
            "julio",
            "agosto",
            "septiembre",
            "octubre",
            "noviembre",
            "diciembre",
        ],
        monthsShort: [
            "ene",
            "feb",
            "mar",
            "abr",
            "may",
            "jun",
            "jul",
            "ago",
            "sep",
            "oct",
            "nov",
            "dic",
        ],
        atDate: "el",
        of: "de",
        unknownError: {
            title: "¡Vaya, algo salió mal!",
            sorry: [
                "Lo sentimos, parece que hemos encontrado un problema que no pudimos identificar.",
                "Pero no te preocupes, nuestro equipo ya está trabajando para solucionarlo.",
            ].join(" "),
            meanwhile: "Mientras tanto, puedes intentar lo siguiente:",
            refreshScreen: "Recargar esta pantalla",
            refreshPage: "Recargar esta página",
            goHome: "Volver al inicio",
            restart: "Reiniciar la app.",
            clearAppData: "Borrar los datos de almacenamiento de la app.",
            tryLater: "Si el problema persiste, por favor intenta nuevamente más tarde.",
            thanks: "Gracias por tu paciencia.",
        },
        showTechnicalDetails: "Mostrar detalles técnicos",
        hideTechnicalDetails: "Ocultar detalles técnicos",
        support: {
            contactUsAt: "Si necesitas ayuda, no dudes en contactarnos a través de ",
            email: "soporte@aimmanager.com",
            or: " o ",
            url: "https://aimmanager.com/contacto/",
        },
        notAvailableForDemo: "No disponible para esta demo",
        routeNotFound: {
            titleNative: "Pantalla no encontrada",
            messageNative: "Lo sentimos, pero esta pantalla no está disponible aún.",
            titleWeb: "Página no encontrada",
            messageWeb1: "Lo sentimos, pero la página que estás buscando ya no existe.",
            messageWeb2: "Si debería existir, revisa que la dirección esté escrita correctamente.",
            goHome: "Volver al inicio",
        },
        confirmDiscardChanges: "¿Desea descartar los cambios sin guardar?",
        confirmExitApp: "¿Salir de la app?",
        seeMore: "Ver más",
    },

    workflows: {
        noWorkflows: "No hay workflows aún.",
        noBpmn: "Este workflow no tiene diagrama BPMN aún.",
        editBpmn: "Editar BPMN",
        discardChanges: "Descartar cambios",
        saveChanges: "Guardar cambios",
        changesSaved: "Cambios guardados",
        active: "Publicado",
        inactive: "Borrador",
        activateWorkflow: "Publicar workflow",
        activateWorkflowConfirm: workflowName =>
            `¿Estás seguro de publicar el workflow "${workflowName}"?\n\n` +
            `No podrás volver a editarlo a menos que crees una nueva versión.`,
        activatingWorkflow: "Publicando workflow...",
        executeWorkflow: "Ejecutar workflow",
        executingWorkflow: "Ejecutando workflow...",
        cannotExecuteInactiveWorkflow:
            "No se puede ejecutar un borrador de workflow. Debes publicarlo primero.",
        cannotEditActiveWorkflow:
            "No se puede editar un workflow publicado. Debes crear una nueva versión.",
        useUserTaskInstead: bpmnElementName =>
            `"${bpmnElementName}" es un elemento de tipo bpmn:Task. Por favor, usa un bpmn:UserTask.`,
        actionSubmit: "Enviar",
        actionSuccess: "Acción realizada con éxito",
        executionId: "ID de ejecución",
        createdAt: "Fecha de creación",
        updatedAt: "Última actualización",
        status: "Estado",
        completionStatuses: {
            [WorkflowCompletionStatus.InProcess]: "En proceso",
            [WorkflowCompletionStatus.Success]: "Completado con éxito",
            [WorkflowCompletionStatus.Error]: "Completado con errores",
        },
        name: "Nombre",
        createWorkflow: "Crear workflow",
        configure: {
            noMessage: "Este evento no gatilla envío de mensaje.",
            addMessage: "Agregar envío de mensaje",
            form: {
                tabTitle: "Formulario",
                elementDoesntHaveIt: "Este elemento no tiene formulario aún.",
                add: "Agregar formulario",
                edit: "Editar formulario",
                confirmSaveChanges:
                    "Antes de proceder con el formulario, necesitamos guardar los cambios al diagrama BPMN.\n\n" +
                    "¿Desea guardar los cambios?",
            },
            message: "Mensaje",
            expiration: "Expiración",
        },
        untitled: elementType => `${capitalize(elementType)} sin título`,
        element: "elemento",
        bpmnElementTypes: {
            "bpmn:IntermediateThrowEvent": "evento de lanzamiento intermedio",
            "bpmn:StartEvent": "evento de inicio",
            "bpmn:UserTask": "tarea de usuario",
        },
    },
};
