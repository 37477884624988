import { createApiMutation } from "../../utils";
import { Organization } from "../organization/interface";

export function createReceiveMagicCodeMutation() {
    return createApiMutation(api => ({
        mutationFn: api.auth.receiveMagicCode,
    }));
}

export function createSignInWithEmailLinkMutation() {
    return createApiMutation(api => ({
        mutationFn: (body: { email: string; link: string }) => api.auth.signInWithEmailLink(body),
    }));
}

export function createSendSignInLinkToEmailMutation() {
    return createApiMutation(api => ({
        mutationFn: (params: { organization: Organization; email: string }) =>
            api.auth.sendSigninLinkToEmail(params.organization, params.email),
    }));
}

export function createSendInviteMutation() {
    return createApiMutation(api => ({
        mutationKey: ["sendInvite"],
        mutationFn: (email: string) => api.auth.sendInvite(email),
    }));
}
