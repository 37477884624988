/** The "person" service has endpoints for user profile and personal data. */
export interface PersonService {
    /** Can be used to get the current user profile without knowing the userId. */
    retrieveCurrentUserProfile(): Promise<UserProfile>;

    /** @see IdentityOptions */
    retrieveIdentityOptions(): Promise<IdentityOptions>;

    /** Returns the `PersonalData` of the current user. */
    retrievePersonalData(): Promise<PersonalData>;

    /** Used when the user updates their personal information. */
    updatePersonalData(data: EditablePersonalData): Promise<void>;
    /**
     *
     * Get all people. Profiles list
     */
    getPeople(): Promise<Person[]>;
    /**
     * Creates a new person.
     * @throws {EmailAlreadyInUseError} if the email is already in use.
     */

    /**
     * Retrieve specific person data
     */
    getPerson(id: string | number): Promise<Person>;

    createPerson(person: PersonalData & { send_invitation: boolean }): Promise<void>;
}

/** Public information about a user. */
export interface UserProfile {
    /** Typically first name and paternal surname. */
    name: string;
    /** E-mail address used to sign in. */
    email: string;
    /** If available, a URL for the avatar or photo for this user. */
    pictureUrl: string | null | undefined;
}

/** Extended version of the `UserProfile` with information that can be used to
 * identify the person behind the user. */
export interface PersonalData extends UserProfile {
    /** The identity documents of the person. May be empty if the user hasn't
     * provided them. */
    identityDocuments: IdentityDocument[];
    /** Telephone numbers of the person in international format,
     * e.g. `"+56912345678"`. May be empty if the user hasn't provided them. */
    phones: string[];
}

/** The subset of the `PersonalData` that can be edited by the user.
 * @see PersonalData */
export type EditablePersonalData = Pick<PersonalData, "name" | "identityDocuments" | "phones">;

export type Person = Omit<PersonalData, "email"> & { id: number; email: string | null };

/** Either a passport or a country-specific "number" used to identify a person.
 * Identity documents are not secrets like US Social Security Number. */
export type IdentityDocument = {
    /** Used to know how we should interpret the `number`. */
    type: IdentityType;
    /** The unique "number" for the person. It's normalized, so it doesn't have
     * any dashes or punctuation characters. If it contains letters, they will
     * be uppercase. */
    number: string;
};

/** Different tenants allow different types of identity documents.
 * For example, a tenant may only allow Chilean RUTs as identity documents.
 * This object specifies the configuration for a specific tenant. */
export interface IdentityOptions {
    /** A list of allowed identity document types, in the order they should
     * appear as options in forms. */
    allowedIdentityTypes: IdentityType[];
    /** The default identity document type in forms. It's assumed to be one
     * of the types in `allowedIdentityTypes`. */
    defaultIdentityType: IdentityType;
}

/** There are different identity document types. It may be a passport or a
 * country-specific "number". Some countries have more than one number, and all
 * of them can be used to identify a person. */
export type IdentityType = (typeof allIdentityTypes)[number];
export const allIdentityTypes = [
    "CL_CI",
    "PASSPORT",
    "PE_DNI",
    "CO_CC",
    "MX_CURP",
    "MX_RFS",
    "MX_INE",
    "MX_NSS",
    "SV_DUI",
] as const;

export type CreatePerson = PersonalData & {
    send_invitation: boolean;
};
