import { checkboxStyling } from "../presentation";
import { LabelAfter, LabelParent } from "./parts/layouts";
import { getCommonInputProps } from "./parts/props";
import { createField, FieldController } from "../state";
import { For, createUniqueId, untrack } from "solid-js";
import { FieldProps } from "./parts/types";

export default function RadioField(
    props: FieldProps<string, HTMLInputElement> & {
        options: [value: string, label: string][];
        parentCheckboxClass?: string;
    },
) {
    const [firstOptionValue] = untrack(() => props.options[0]) ?? [""];
    const field = createField(props, firstOptionValue);
    const inputId = createUniqueId();

    return (
        <LabelParent
            {...props}
            field={field}
            inputId={inputId}
            labelClass={`flex-col !items-start ${props.labelClass}`}
        >
            <div class={props.parentCheckboxClass}>
                <For each={props.options}>
                    {option => <RadioOption {...props} option={option} field={field} />}
                </For>
            </div>
        </LabelParent>
    );
}

function RadioOption(
    props: FieldProps<string, HTMLInputElement> & {
        option: [value: string, label: string];
        field: FieldController<string>;
    },
) {
    const inputId = createUniqueId();
    const optionValue = () => props.option[0];
    const optionLabel = () => props.option[1];

    return (
        <LabelAfter {...props} label={optionLabel()} inputId={inputId}>
            <input
                {...getCommonInputProps(inputId, props)}
                {...checkboxStyling}
                type="radio"
                value={optionValue()}
                checked={optionValue() === props.field.value}
                onChange={event => {
                    props.field.setValue(event.currentTarget.value);
                }}
            />
        </LabelAfter>
    );
}
