import {
    allIdentityTypes,
    EditablePersonalData,
    IdentityOptions,
    Person,
    PersonalData,
    PersonService,
    UserProfile,
} from "../interface";
import { getUserOr401 } from "../../../../modules/auth/authContext";

export default class MockPersonService implements PersonService {
    private personalDataEdits: Record<string, EditablePersonalData> = {};

    retrieveCurrentUserProfile = async (): Promise<UserProfile> => {
        return await this.retrievePersonalData();
    };

    retrieveIdentityOptions = async (): Promise<IdentityOptions> => {
        return {
            allowedIdentityTypes: [...allIdentityTypes],
            defaultIdentityType: "CL_CI",
        };
    };

    retrievePersonalData = async (): Promise<PersonalData> => {
        const user = getUserOr401();
        const profile: PersonalData = {
            name: user.name,
            email: user.email,
            pictureUrl: user.picture,
            identityDocuments: [],
            phones: [],
        };
        if (this.personalDataEdits[user.id]) {
            Object.assign(profile, this.personalDataEdits[user.id]);
        }
        return profile;
    };

    updatePersonalData = async (data: EditablePersonalData): Promise<void> => {
        const user = getUserOr401();
        this.personalDataEdits[user.id] = data;
    };

    getPeople = async (): Promise<Person[]> => {
        return [];
    };

    getProfileList = async (): Promise<Person[]> => {
        return [];
    };

    createPerson(person: PersonalData): Promise<void> {
        const user = getUserOr401();
        this.personalDataEdits = { ...this.personalDataEdits, [user.id]: person };
        return Promise.resolve();
    }
    getPerson(id: number): Promise<Person> {
        const person = this.personalDataEdits[id];
        if (person) {
            return Promise.resolve({
                email: "test@email.com",
                name: person.name,
                pictureUrl: "",
                phones: person.phones,
                identityDocuments: person.identityDocuments,
                id: id,
            });
        } else {
            throw new Error("Person not found");
        }
    }
}
