import { Activity, ActivityTask, TaskManagerService } from "../interface";
import { makeServiceQuery } from "../../../utils";
import { workflowClient } from "../../../../modules/client/client";
import { Infer, array, string, type } from "superstruct";
import ICAL from "ical.js";
import {
    ActivityRecurrence,
    decomposeCalendar,
    VTodoRecurrence,
} from "../../../../modules/Activities/ActivitiesPlaygroundPage";
import { WorkflowExecution } from "../../workflow/interface";

export default class V3TaskManagerService implements TaskManagerService {
    get paginateMyActivities(): never {
        throw new Error("Not implemented");
    }

    retrieveMyCalendar = makeServiceQuery({
        fetchJson: async () => workflowClient.get("/activities/calendar/").receiveJson(),
        responseSchema: type({ calendar: string() }),
        deserialize: payload => {
            const vCalendar = decomposeCalendar(new ICAL.Component(ICAL.parse(payload.calendar)));
            if (vCalendar.todos.some(todo => !todo.rrule || todo.recurrenceId)) {
                throw new Error(
                    "Only todos with rrule and no recurrenceId are supported by the frontend right now",
                );
            }
            const activityRecurrences = (vCalendar.todos as VTodoRecurrence[]).map(
                todoRecurrence => new ActivityRecurrence(todoRecurrence),
            );
            return {
                getActivities(
                    startDate: Temporal.PlainDate,
                    endDate: Temporal.PlainDate,
                ): Activity[] {
                    return activityRecurrences
                        .flatMap(ar => [...ar.generateOccurrences(startDate, endDate)])
                        .sort(Activity.compare);
                },
            };
        },
    });

    handleNewWorkflowExecution = (_execution: WorkflowExecution) => {
        /* Do nothing. This method is just a hack for the mock service. */
    };

    async createActivity(activity: unknown) {
        console.log("Creating activity", activity);
        throw new Error("Not implemented");
    }
    createActivityTask(activityId: string): void {
        console.log("create activity task", activityId);
        throw new Error("Not implemented");
    }

    activateActivity(activityId: string): void {
        console.log("activate activity", activityId);
        throw new Error("Not implemented");
    }

    getActivityTasks = makeServiceQuery({
        fetchJson: async () => workflowClient.get("/activities/activity-tasks/").receiveJson(),
        responseSchema: array(sV3ActivityTask()),
        deserialize: tasks => tasks.map(deserializeActivityTask),
    });

    recurrenceActivities(id: string): void {
        console.log("recurrence activities", id);
        throw new Error("Not implemented");
    }

    startEvent(): void {
        console.log("start event");
        throw new Error("Not implemented");
    }

    messageStartEvent(): void {
        console.log("message start event");
        throw new Error("Not implemented");
    }

    checklistExecutionStartEvent(): void {
        console.log("checklist execution start event");
        throw new Error("Not implemented");
    }

    formResponseStartEvent(): void {
        console.log("form response start event");
        throw new Error("Not implemented");
    }
}

export function sV3ActivityTask() {
    return type({
        id: string(),
        activity: string(),
        object_id: string(),
        content_type_model: string(),
    });
}
type V3ActivityTask = Infer<ReturnType<typeof sV3ActivityTask>>;
function deserializeActivityTask(raw: V3ActivityTask): ActivityTask {
    return raw;
}
