import {
    createWorkflowDetailQuery,
    createWorkflowExecutionListQuery,
} from "../../api/services/workflow/queries";
import { WorkflowExecution } from "../../api/services/workflow/interface";
import { BorderedCard } from "../ui/cards";
import { For, Show, Suspense } from "solid-js";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { P } from "../../utils/typography";
import { useLocale } from "../i18n/context";

export default function WorkflowExecutionList() {
    const workflowExecutionListQuery = createWorkflowExecutionListQuery();

    return (
        <Suspense fallback={<GenericSuspenseFallback />}>
            <Show when={workflowExecutionListQuery.data}>
                {executionList => (
                    <div class={"my-3 grid grid-cols-1 flex-wrap gap-3 md:grid-cols-12"}>
                        <For each={executionList()} fallback={<NoWorkflowExecutions />}>
                            {execution => <WorkflowExecutionItem execution={execution} />}
                        </For>
                    </div>
                )}
            </Show>
        </Suspense>
    );
}

function NoWorkflowExecutions() {
    return <pre>NoWorkflowExecutions</pre>;
}

function WorkflowExecutionItem(props: { execution: WorkflowExecution }) {
    const workflowDetailQuery = createWorkflowDetailQuery(() => props.execution.workflowId);
    //const navigate = useNavigate();
    const [locale] = useLocale();

    return (
        <BorderedCard
            // window.location.href as workaround for weird bug where the execution detail query is endlessly loading
            onClick={() => (window.location.href = `/workflows/executions/${props.execution.id}`)}
            class={"col-span-1 md:col-span-4"}
        >
            <Show when={workflowDetailQuery.data}>
                {workflow => <P class={"font-semibold"}>{workflow().name}</P>}
            </Show>
            <div class={"flex items-center gap-x-2"}>
                <span
                    class={"block h-2 w-2 rounded-full"}
                    classList={{
                        "bg-success-400": props.execution.status === "Success",
                        "bg-error-300": props.execution.status === "InProcess",
                    }}
                />
                <P class={"!mb-0"}>
                    {props.execution.status === "Success" ? "Finalizada" : "En proceso"}
                </P>
            </div>
            <P class={"!mb-0"}>
                Creado:{" "}
                {Intl.DateTimeFormat(locale().codeWithCountry, {
                    dateStyle: "long",
                    timeStyle: "short",
                }).format(props.execution.createdAt)}
            </P>
            <P>
                Última actualización:
                {Intl.DateTimeFormat(locale().codeWithCountry, {
                    dateStyle: "long",
                    timeStyle: "short",
                }).format(props.execution.updatedAt)}
            </P>
            {/*<JsonDebug value={props.execution} />*/}
        </BorderedCard>
    );
}
