import "filepond/dist/filepond.min.css";
import { createUniqueId, onCleanup, onMount, ParentProps } from "solid-js";
import { LabelAbove } from "./parts/layouts";
import { getCommonInputProps } from "./parts/props";
import { createField } from "../state";
import { FieldProps } from "./parts/types";
import * as FilePond from "filepond";
import { createRef } from "../../../utils/reactRefs";
import { parsedEnv } from "../../../utils/parsedEnv";

export default function FileField(
    props: ParentProps<FieldProps<{ temporary_upload: string } | undefined, HTMLInputElement>>,
) {
    const field = createField(props, undefined);
    const inputId = createUniqueId();
    const inputRef = createRef<HTMLInputElement>();

    onMount(() => {
        // Use options based on https://github.com/ImperialCollegeLondon/django-drf-filepond?tab=readme-ov-file#chunked-uploads
        FilePond.setOptions({
            chunkUploads: true,
            chunkSize: 500_000,
            server: {
                url: parsedEnv.VITE_SERVER_WORKFLOW + "/filepond",
                process: {
                    url: "/process/",
                    /* V3 only accepts the file if the input name is "filepond",
                     * here we simulate that by crafting the request that would be sent in that case.
                     * For context, see https://github.com/ImperialCollegeLondon/django-drf-filepond/issues/4 */
                    ondata: data => {
                        const file = data.getAll(props.name).find(value => value instanceof File)!;
                        data.delete(props.name);
                        data.append("filepond", "{}");
                        data.append("filepond", file);
                        return data;
                    },
                    onload: (temporary_upload: string) => {
                        field.setValue({ temporary_upload });
                        return temporary_upload;
                    },
                },
                // @ts-expect-error - Official typings don't include this for some reason
                patch: "/patch/",
                revert: "/revert/",
                fetch: "/fetch/?target=",
            },
        });
        FilePond.create(inputRef.current!);
    });
    onCleanup(() => FilePond.destroy(inputRef.current!));

    return (
        <LabelAbove {...props} field={field} inputId={inputId}>
            <input
                type="file"
                {...getCommonInputProps(inputId, props)}
                class="filepond"
                ref={inputRef}
            />
        </LabelAbove>
    );
}
