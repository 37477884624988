import {
    Accessor,
    children,
    createContext,
    createSignal,
    For,
    JSX,
    ParentProps,
    Setter,
} from "solid-js";
import { Button } from "./components";
import { useRequiredContext } from "../../utils/solidjs";
import { array, create, define, string, StructError, type, union } from "superstruct";

type TabsProps = {
    staticController?: TabsController;
    children: JSX.Element;
    parentClass?: string;
};
type TabProps = {
    title: string;
    children: JSX.Element;
};
const TabsContext = createContext<boolean>();
export function Tabs(props: TabsProps) {
    return (
        <TabsContext.Provider value={true}>
            <TabsInner {...props} />
        </TabsContext.Provider>
    );
}

type TabsController = {
    activeTab: Accessor<number>;
    setActiveTab: Setter<number>;
};

export function createTabsController(): TabsController {
    const [activeTab, setActiveTab] = createSignal(0);
    return { activeTab, setActiveTab };
}

function TabsInner(props: TabsProps) {
    const { activeTab, setActiveTab } = props.staticController ?? createTabsController();

    const validatedTabs = useChildrenProps({
        children: props.children,
        class: TabComponent,
        className: "Tab",
    });

    return (
        <div class={props.parentClass}>
            <ul class="flex items-end border-b border-b-light-gray-300">
                <For each={validatedTabs()}>
                    {(tab, index) => {
                        return (
                            <li class={"first:ml-0 ml-px"}>
                                <Button
                                    variant="primary"
                                    // bgStyle={activeTab() === index() ? "solid" : "outline"}
                                    bgStyle={"outline"}
                                    onClick={() => setActiveTab(index())}
                                    class={`rounded-b-0 -mb-px !border-light-gray-300 px-4 py-4 !font-normal ${
                                        activeTab() === index()
                                            ? "!border-b-white bg-white !text-dark-gray-700"
                                            : "bg-light-gray-50 !text-dark-gray-400"
                                    }`}
                                >
                                    {tab.props.title}
                                </Button>
                            </li>
                        );
                    }}
                </For>
            </ul>
            <div>{validatedTabs()[activeTab()].props.children}</div>
        </div>
    );
}

function useChildrenProps<TClass, TProps>(config: {
    children: JSX.Element;
    class: { new (props: TProps): TClass };
    className: string;
}) {
    const resolvedChildren = children(() => config.children);
    const evaluatedChildren = () => resolvedChildren.toArray() as unknown as TabComponent[];
    const validatedChildren = () => {
        try {
            return create(
                evaluatedChildren(),
                array(
                    union([
                        define(config.className, x => x instanceof config.class),
                        //Hot reload case
                        type({
                            name: string(),
                        }),
                    ]),
                ),
            ) as unknown as TabComponent[];
        } catch (e) {
            if (e instanceof StructError) {
                throw new Error(`Tabs children must be Tab components. ${e.message}`);
            }
            throw e;
        }
    };
    return validatedChildren;
}

export function Tab(props: ParentProps<TabProps>) {
    useRequiredContext(TabsContext, "Tab", "Tabs");
    return new TabComponent(props) as unknown as JSX.Element;
}

export class TabComponent {
    constructor(readonly props: TabProps) {}
}
