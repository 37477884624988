import { createContext, createSignal, ParentProps, Show } from "solid-js";
import { useRequiredContext } from "../../utils/solidjs";
import { Button } from "./components";

const AccordionContext = createContext();

export default function Accordion(props: ParentProps) {
    return <AccordionContext.Provider value={{}}>{props.children}</AccordionContext.Provider>;
}

export function AccordionItem(props: ParentProps<{ summary: string; summaryOpen?: string }>) {
    useRequiredContext(AccordionContext, "AccordionItem", "Accordion");
    const [isOpen, setIsOpen] = createSignal(false);
    const toggle = () => setIsOpen(x => !x);

    return (
        <>
            <Button
                onClick={toggle}
                icon={isOpen() ? "fas fa-caret-down" : "fas fa-caret-right"}
                bgStyle="text-only"
            >
                {isOpen() && props.summaryOpen ? props.summaryOpen : props.summary}
            </Button>
            <Show when={isOpen()}>{props.children}</Show>
        </>
    );
}
