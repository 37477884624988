import {
    Accessor,
    createContext,
    createMemo,
    createSignal,
    JSX,
    ParentProps,
    Show,
} from "solid-js";
import { fireAndForget } from "../../utils/async";
import { createRef, MutableRefObject } from "../../utils/reactRefs";
import "./Modal.css";
import { useRequiredContext } from "../../utils/solidjs";
import { LavandaCard } from "./cards";

const ModalContext = createContext<ModalProps<unknown, unknown>>();

export type ModalController<TData, TAnswer> = {
    /** Opens the modal synchronously without waiting for the user's answer. */
    open: (data: TData) => void;

    /** Closes the modal without the user giving an answer. */
    dismiss: () => void;

    /** Opens the modal and asynchronously awaits the user's answer.
     * If the user dismisses the modal, the promise will resolve to null. */
    prompt: (data: TData) => Promise<TAnswer | null>;

    /** Closes the modal and returns the given answer to the `prompt` promise. */
    closeWithAnswer: (answer: TAnswer) => void;

    /** Used by the `Modal` component to render the modal.
     * You can also use `state().isOpen` to check if the modal is open. */
    state: Accessor<ModalState<TData, TAnswer>>;

    /** A ref to underlying  */
    dialogRef: MutableRefObject<HTMLDialogElement>;
};

type ModalState<TData, TAnswer> = OpenModalState<TData> | ClosedModalState<TAnswer>;
type OpenModalState<TData> = { isOpen: true; data: TData };
type ClosedModalState<TAnswer> = { isOpen: false; answer: TAnswer | null };

/** You can create a simple modal with `createModalController<void, void>()`.
 * By default, the modal is closed.
 * You can pass an `initialState` of `{ isOpen: true  }` or
 * `{ isOpen: true, data: ... }` to open it immediately. */
export function createModalController<TData, TAnswer>(
    initialState?: ModalState<TData, TAnswer>,
): ModalController<TData, TAnswer> {
    const dialogRef = createRef<HTMLDialogElement>();
    const [state, setState] = createSignal<ModalState<TData, TAnswer>>(
        initialState ?? { isOpen: false, answer: null },
    );
    let resolve: ((answer: TAnswer | null) => void) | undefined = undefined;

    function open(data: TData): void {
        fireAndForget(prompt(data));
    }

    function dismiss(): void {
        closeWithAnswer(null);
        dialogRef.current?.close();
    }

    async function prompt(data: TData): Promise<TAnswer | null> {
        if (resolve) throw new Error("Modal is already open");
        dialogRef.current?.showModal();
        setState({ isOpen: true, data });
        return new Promise(r => (resolve = r));
    }

    function closeWithAnswer(answer: TAnswer | null) {
        if (!resolve) throw new Error("Modal is not open");
        setState({ isOpen: false, answer });
        resolve(answer);
        resolve = undefined;
        dialogRef.current?.close();
    }

    return { open, dismiss, prompt, closeWithAnswer, state, dialogRef };
}

type ModalProps<TData, TAnswer> = {
    controller: ModalController<TData, TAnswer>;
    children?: JSX.Element | ((data: TData) => JSX.Element);
    containerClass?: string;
    confirmMode?: boolean;
    size?: keyof typeof sizeMap;
};

const sizeMap = {
    md: "max-w-[500px] !pt-3 md:w-[50vw]",
} as const;

export function Modal<TData, TAnswer>(props: ModalProps<TData, TAnswer>) {
    const state = createMemo(() => props.controller.state());
    const sizeClass = () => sizeMap[props.size ?? "md"];

    return (
        <ModalContext.Provider value={props as ModalProps<unknown, unknown>}>
            <dialog
                ref={props.controller.dialogRef}
                onClick={() => {
                    if (!props.confirmMode) props.controller.dismiss();
                }}
                onClose={() => props.controller.dismiss()}
                class={"customDialog duration-200 ease-in-out"}
                onCancel={e => {
                    if (props.confirmMode) {
                        e.preventDefault();
                    }
                }}
            >
                <div class="center-items relative z-modal h-full w-full overflow-y-auto px-4 md:px-0">
                    <LavandaCard
                        onClick={e => e.stopPropagation()}
                        class={`${props.containerClass} ${sizeClass() ?? ""}`}
                    >
                        <ModalContentRender state={state()}>{props.children}</ModalContentRender>
                    </LavandaCard>
                </div>
            </dialog>
        </ModalContext.Provider>
    );
}

/** Used by Modal, SidePanel, or future modal implementations,
 * to support using a render function as `props.children`.
 */
export function ModalContentRender<TData, TAnswer>(props: {
    state: ModalState<TData, TAnswer>;
    children?: JSX.Element | ((data: TData) => JSX.Element);
}) {
    return (
        <Show when={props.state.isOpen && (props.state as OpenModalState<TData>)}>
            {state =>
                typeof props.children === "function" ? props.children(state().data) : props.children
            }
        </Show>
    );
}

Modal.Header = function ModalHeader(props: ParentProps) {
    const { controller, confirmMode } = useRequiredContext(ModalContext, "ModalHeader", "Modal");

    return (
        <header class={"flex items-center justify-between"}>
            <div>{props.children}</div>
            {!confirmMode && (
                <div
                    class={"flex h-8 w-8 cursor-pointer items-center justify-center"}
                    onClick={() => controller.dismiss()}
                >
                    <i class="fas fa-times text-lg text-light-gray-400" />
                </div>
            )}
        </header>
    );
};
