import { Paginated, PaginationParams, ServiceQuery } from "../../utils";
import { WorkflowExecution } from "../workflow/interface";
import { VTodo } from "../../../modules/Activities/ActivitiesPlaygroundPage";

export interface TaskManagerService {
    /** @deprecated - Use sliceUserActivities instead. */
    paginateMyActivities: ServiceQuery<[params: PaginationParams], Paginated<Activity>>;

    // /** @deprecated - Use retrieveMyCalendar instead.
    //  * Gets the activities visible in "My Activities" for a date range.
    //  * A date range is needed as recurrences may create infinite activities.
    //  * The range is inclusive-inclusive. */
    // sliceUserActivities: ServiceQuery<
    //     [startDate: Temporal.PlainDate, endDate: Temporal.PlainDate],
    //     Activity[]
    // >;

    /** Calendar corresponding to "My Activities". */
    retrieveMyCalendar: ServiceQuery<[], Calendar>;

    /** Hack for the workflows demo. */
    handleNewWorkflowExecution(execution: WorkflowExecution): void;

    createActivity(activity: unknown): void; //POST activities/
    createActivityTask(activityId: string): void; //POST activity-tasks/
    activateActivity(activityId: string): void; //POST activities/{activityId}/activate/
    getActivityTasks: ServiceQuery<[], ActivityTask[]>; //GET activity-tasks/
    recurrenceActivities(id: string): void; //POST en recurrence-activities/
    startEvent(): void; //recurrence-tasks/start-event/
    messageStartEvent(): void; //recurrence-tasks/message-start-event/
    checklistExecutionStartEvent(): void; //recurrence-tasks/checklist-execution/
    formResponseStartEvent(): void; // recurrence-tasks/form-response/
}

/** An abstract collection of activity recurrences. */
export interface Calendar {
    /** Calendars may have infinite activities because of recurrences.
     * So this returns activities within a given date range.
     * @param startDate - Inclusive start of date range.
     * @param endDate - Inclusive end of date range.
     * @example
     * // Activities for today and tomorrow
     * calendar.getActivities(
     *     Temporal.Now.plainDateIso(),
     *     Temporal.Now.plainDateIso().add({ days: 1 }),
     * )
     */
    getActivities(startDate: Temporal.PlainDate, endDate: Temporal.PlainDate): Activity[];
}

export enum ActivityType {
    Example = "Example",
    Workflow = "Workflow",
}

type ActivityAttrs = {
    id: string;
    title: string;
    group: { name: string };
    priority: Priority;
    dueDate: Temporal.ZonedDateTime;
};

export abstract class Activity implements ActivityAttrs {
    id: string;
    title: string;
    group: { name: string };
    priority: Priority;
    dueDate: Temporal.ZonedDateTime;

    constructor(attrs: ActivityAttrs) {
        this.id = attrs.id;
        this.title = attrs.title;
        this.group = attrs.group;
        this.priority = attrs.priority;
        this.dueDate = attrs.dueDate;
    }

    static fromVTodo(vTodo: VTodo, dueDate: Temporal.ZonedDateTime): Activity {
        return new ExampleActivity({
            id: vTodo.uid,
            title: vTodo.summary,
            group: { name: "Grupo provisorio" },
            priority: Priority.Standard,
            dueDate,
        });
    }

    static compare(a: Activity, b: Activity): number {
        return (
            // first by priority
            a.priority - b.priority ||
            // then by due date
            Temporal.ZonedDateTime.compare(a.dueDate, b.dueDate)
        );
    }

    // navigate(this.href) should go to the activity detail
    abstract href: string;
}

export class ExampleActivity extends Activity {
    get href() {
        return `/activities/${this.id}`;
    }
}

export class WorkflowActivity extends Activity {
    readonly executionId: string;

    constructor(attrs: ActivityAttrs & { executionId: string }) {
        super(attrs);
        this.executionId = attrs.executionId;
    }

    get href() {
        return `/workflows/executions/${this.executionId}`;
    }
}

/** Activities are sorted by priority, then by deadline.
 *
 * A smaller number is a higher priority, so
 * `sortBy(activities, a => a.priority)` works as intended.
 */
export enum Priority {
    Reminder = -4,
    ThreeUpArrows = -3,
    TwoUpArrows = -2,
    OneUpArrow = -1,
    Standard = 0,
    // There is no low priority in Latin America.
}

export type ActivityTask = {
    id: string;
    activity: string;
    content_type_model: string;
    object_id: string;
};
