import { FieldProps } from "./parts/types";
import { LabelAbove } from "./parts/layouts";
import { createField } from "../state";
import { createUniqueId, For } from "solid-js";
import { P } from "../../../utils/typography";
import { checkboxStyling } from "../presentation";

export default function CheckboxesField(
    props: FieldProps<string[], void> & {
        options: [value: string, label: string][];
    },
) {
    const field = createField(props, []);
    const inputId = createUniqueId();

    return (
        <LabelAbove {...props} field={field} inputId={inputId}>
            <div class="grid gap-x-2 gap-y-1" style={{ "grid-template-columns": "auto 1fr" }}>
                <For each={props.options} fallback={<P>Campo checkboxes sin opciones</P>}>
                    {([optionValue, label]) => (
                        <Checkbox
                            label={label}
                            checked={field.value.includes(optionValue)}
                            onCheck={() => field.setValue([...field.value, optionValue])}
                            onUncheck={() =>
                                field.setValue(field.value.filter(x => x !== optionValue))
                            }
                        />
                    )}
                </For>
            </div>
        </LabelAbove>
    );
}

function Checkbox(props: {
    label: string;
    checked: boolean;
    onCheck: () => void;
    onUncheck: () => void;
}) {
    const inputId = createUniqueId();

    return (
        <>
            <input
                id={inputId}
                type="checkbox"
                class={checkboxStyling.class}
                style={checkboxStyling.style}
                checked={props.checked}
                onChange={e => (e.target.checked ? props.onCheck() : props.onUncheck())}
            />
            <label for={inputId}>{props.label}</label>
        </>
    );
}
