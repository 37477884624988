import { Locale } from "../i18n/Locale";

export const menuTabs: Record<keyof Locale["menu"]["tabs"], { href: string; icon: string }> = {
    home: { href: "/home", icon: "fas fa-home" },
    posts: { href: "/posts", icon: "far fa-comment-dots" },
    activities: { href: "/activities", icon: "far fa-clipboard" },
    linksOfInterest: { href: "/links", icon: "fas fa-link" },
    reports: { href: "/reports", icon: "fas fa-chart-pie" },
    assets: { href: "/assets", icon: "fas fa-box" },
    organization: { href: "/people", icon: "fas fa-users" },
    checklists: { href: "/checklists", icon: "fas fa-pen" },
    audiences: { href: "/audiences", icon: "fas fa-pen" },
    workflows: { href: "/workflows", icon: "fas fa-project-diagram" },
};
