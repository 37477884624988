import { FirebaseAuthentication, SignInCustomParameter } from "@capacitor-firebase/authentication";
import {
    OutsideOrganizationError,
    PopupBlockedError,
    PopupClosedByUserError,
    WrongPasswordError,
} from "../interface";
import { sleep } from "../../../../utils/mocks";
import { adaptUser, dispatchSignInEvent, getUserOr401 } from "../../../../modules/auth/authContext";
import { enums, is, literal, pattern, string, type, union } from "superstruct";
import { Capacitor } from "@capacitor/core";
import { useLocale } from "../../../../modules/i18n/context";
import { onMount } from "solid-js";
import { useThrowToErrorBoundary } from "../../../../utils/solidjs";
import { Organization } from "../../organization/interface";
import { parsedEnv } from "../../../../utils/parsedEnv";

class FirebaseAuthService {
    signInWithEmailAndPassword = async (
        organization: Organization,
        email: string,
        password: string,
    ): Promise<void> => {
        await this.setTenantId(organization.firebaseTenantId);

        try {
            await FirebaseAuthentication.signInWithEmailAndPassword({ email, password });
        } catch (error) {
            if (isFirebaseWrongCredentialsError(error)) throw new WrongPasswordError();
            else throw error;
        }
    };

    signInWithGoogle = async (
        organization: Organization,
        email: string | undefined,
    ): Promise<void> => {
        const customParameters: SignInCustomParameter[] = [];
        if (email) customParameters.push({ key: "login_hint", value: email });

        /* If the user chose the wrong account, let them choose again. */
        // - Desktop: https://stackoverflow.com/questions/62043170/firebase-google-auth-wont-let-me-choose-the-account-to-connect-with
        customParameters.push({ key: "prompt", value: "select_account" });
        // - Android: https://stackoverflow.com/questions/72058545/firebase-google-auth-automatically-selecting-user-how-to-force-it-to-pop-up-gma
        await FirebaseAuthentication.signOut();
        // This combination also works on iOS.

        try {
            await this.setTenantId(organization.firebaseTenantId);
            await FirebaseAuthentication.signInWithGoogle({ customParameters });
        } catch (error) {
            if (isPopupBlockedError(error)) throw new PopupBlockedError();
            if (isPopupClosedByUserError(error)) throw new PopupClosedByUserError();
            if (isAdminRestrictedError(error)) throw new OutsideOrganizationError();
            throw error;
        }
    };

    sendPasswordResetEmail = async (organization: Organization, email: string): Promise<void> => {
        await this.setTenantId(organization.firebaseTenantId);
        await FirebaseAuthentication.sendPasswordResetEmail({ email });
    };

    getIdToken = async (maxRetries = 10): Promise<string> => {
        try {
            const { token } = await FirebaseAuthentication.getIdToken();
            return token;
        } catch (error) {
            if (maxRetries === 0) throw error;
            await sleep(200);
            return await this.getIdToken(maxRetries - 1);
        }
    };

    signOut = async (): Promise<void> => {
        await FirebaseAuthentication.signOut();
    };

    sendSigninLinkToEmail = async (organization: Organization, email: string): Promise<void> => {
        const host = window.location.host;
        const protocol = window.location.protocol;
        const url = Capacitor.isNativePlatform()
            ? `${parsedEnv.VITE_WEB_URL}/from-magic-link`
            : `${protocol}//${host}/from-magic-link`;
        await this.setTenantId(organization.firebaseTenantId);
        return await FirebaseAuthentication.sendSignInLinkToEmail({
            email,
            actionCodeSettings: {
                url: url,
                handleCodeInApp: true,
            },
        });
    };

    sendInvite = async (email: string): Promise<void> => {
        const user = getUserOr401();
        const host = window.location.host;
        const protocol = window.location.protocol;
        const url = Capacitor.isNativePlatform()
            ? `${parsedEnv.VITE_WEB_URL}/from-invite`
            : `${protocol}//${host}/from-invite`;
        await this.setTenantId(user.firebaseTenantId);
        return await FirebaseAuthentication.sendSignInLinkToEmail({
            email,
            actionCodeSettings: {
                url: url,
                handleCodeInApp: true,
            },
        });
    };

    isSignInWithEmailLink = async (link: string) => {
        const res = await FirebaseAuthentication.isSignInWithEmailLink({ emailLink: link });
        return res.isSignInWithEmailLink;
    };

    signInWithEmailLink = async ({ email, link }: { email: string; link: string }) => {
        // We don't need to `setTenantId` as the `link` includes the `tenantId`.
        const res = await FirebaseAuthentication.signInWithEmailLink({ email, emailLink: link });
        if (res.user) {
            dispatchSignInEvent(adaptUser(res.user));
        } else {
            throw new Error("Sign in with email link failed");
        }
    };

    private async setTenantId(tenantId: string | null): Promise<void> {
        /* Caveat: FirebaseAuthentication doesn't allow setting the tenantId
         * back to null because the Firebase SDK for Android doesn't allow it.
         * For context, see https://github.com/firebase/firebase-android-sdk/issues/3398.
         * `FirebaseAuthentication.setTenantId({ tenantId: null })` works on web,
         * but not on iOS even if the Firebase SDK for iOS allows resetting it to null.
         *
         * As a workaround, if the user is on mobile and their organization
         * would make us reset the tenantId to null, we ask the user to restart
         * the app, as null is the default tenantId.
         * See `FirebaseAuthService.useRestartCheck`. */
        if (!(Capacitor.isNativePlatform() && tenantId === null))
            await FirebaseAuthentication.setTenantId({ tenantId: tenantId! });
    }

    /** Workaround for issue with FirebaseAuthService.setTenantId. */
    useRestartCheck(organization: Organization): void {
        const [locale] = useLocale();
        const throwToErrorBoundary = useThrowToErrorBoundary();

        onMount(async () => {
            if (Capacitor.isNativePlatform() && organization.firebaseTenantId === null) {
                const prev = await FirebaseAuthentication.getTenantId();
                if (prev.tenantId !== null) {
                    const message = locale().auth.restartNeeded(organization.name);
                    alert(message);
                    /* Crash the app so the user is forced to manually close it.
                     * Note that `App.exitApp()` and `window.location.reload()`
                     * won't close the app. */
                    throwToErrorBoundary(new Error(message));
                }
            }
        });
    }
}

export const firebaseAuthService = new FirebaseAuthService();

function isFirebaseWrongCredentialsError(error: unknown): boolean {
    return is(
        error,
        type({
            code: enums([
                "auth/wrong-password",
                "wrong-password",
                "auth/user-not-found",
                "user-not-found",
                "auth/invalid-email",
                "invalid-email",
                "auth/invalid-credential",
                "invalid-credential",
            ]),
        }),
    );
}

function isPopupBlockedError(error: unknown): boolean {
    return is(
        error,
        type({
            code: enums(["auth/popup-blocked", "popup-blocked"]),
        }),
    );
}

function isPopupClosedByUserError(error: unknown): boolean {
    return (
        // Web
        (error instanceof Object &&
            "code" in error &&
            error.code === "auth/popup-closed-by-user") ||
        // Android
        (error instanceof Error && error.message.includes("12501")) ||
        // iOS
        is(
            error,
            type({
                message: pattern(string(), /user canceled/i),
            }),
        )
    );
}

function isAdminRestrictedError(error: unknown): boolean {
    return is(
        error,
        union([
            type({
                code: enums(["auth/admin-restricted-operation", "admin-restricted-operation"]),
            }),
            type({
                message: literal("ADMIN_ONLY_OPERATION"),
            }),
        ]),
    );
}
