import { SignInMethod as SignInMethodPseudoEnum } from "@firebase/auth";
import { Organization } from "../organization/interface";
import { string, type } from "superstruct";

/** The "auth" service has endpoints for post-onboarding authentication. */
export interface AuthService {
    /** Returns the workspaces where `email` can sign in, in the order they should
     * appear on the workspace selection step (or workspace switcher).
     * @deprecated - Workspace are not needed anymore. The "select workspace"
     * step should be deleted. */
    listWorkspaces(email: string): Promise<WorkspaceOverview[]>;

    /** Sign in with email and password using the appropriate authentication
     * provider. Must dispatch a SignIn event on success, or throw
     * WrongPasswordError if the email and password don't match.
     * @see dispatchSignInEvent
     * @throws WrongPasswordError */
    signInWithEmailAndPassword(
        organization: Organization,
        email: string,
        password: string,
    ): Promise<void>;

    /** Sign in with Google using the appropriate authentication provider.
     * Must call {@link dispatchSignInEvent} on success, or throw.
     * @param organization - Only allow accounts with access to this organization.
     * @param email - If provided, this email will be prefilled during the Google sign-in flow.
     * @throws PopupBlockedError
     * @throws PopupClosedByUserError
     * @throws OutsideOrganizationError
     */
    signInWithGoogle(organization: Organization, email: string | undefined): Promise<void>;

    /** Used when user clicks "Forgot password".
     * Sends an email with instructions to reset password.
     * If `email` is not registered, we still tell the user the email was sent
     * (a lie, as we actually do nothing) to prevent an "email enumeration"
     * vulnerability. */
    sendPasswordResetEmail(organization: Organization, email: string): Promise<void>;

    /** Sign out from the authentication provider.
     * Must dispatch a SignOut event on success.
     * @see dispatchSignOutEvent */
    signOut(): Promise<void>;

    /** https://github.com/aimmanager/gargamel-api-docs/blob/main/Services/Auth.md#send-magic-code */
    sendMagicCode(email: string): Promise<void>;

    /** https://github.com/aimmanager/gargamel-api-docs/blob/main/Services/Auth.md#receive-magic-code
     *
     * Returns null if the magic code didn't match or the email isn't registered. */
    receiveMagicCode(request: ReceiveMagicCodeRequest): Promise<ReceiveMagicCodeResponse | null>;

    /** https://capawesome.io/plugins/firebase/authentication/#sendsigninlinktoemail
     *
     * Used to sign in user from magic link
     */
    sendSigninLinkToEmail(organization: Organization, email: string): Promise<void>;

    /**
     *
     * Used to create and invite a new user. Firebase will send an email to the email address
     */
    sendInvite(email: string): Promise<void>;

    /** https://capawesome.io/plugins/firebase/authentication/#issigninwithemaillink
     *
     * Used to validate the integrity of the link sent to the user's email address
     */
    isSignInWithEmailLink(link: string): Promise<boolean>;

    /** https://capawesome.io/plugins/firebase/authentication/#signinwithemaillink
     *
     * Used to sign in the user after they have clicked the link sent to their email address
     */
    signInWithEmailLink(params: { email: string; link: string }): Promise<void>;
}

export enum BackendVersion {
    Atlas = "atlas",
    V1 = "v1",
}

export class WrongPasswordError extends Error {
    constructor() {
        super("Wrong password");
        Object.setPrototypeOf(this, WrongPasswordError.prototype);
        Error.captureStackTrace?.(this, WrongPasswordError);
        this.name = this.constructor.name;
    }
}
export class WrongEmailFormatError extends Error {
    constructor() {
        super("Wrong email format");
        Object.setPrototypeOf(this, WrongEmailFormatError.prototype);
        Error.captureStackTrace?.(this, WrongEmailFormatError);
        this.name = this.constructor.name;
    }
}

/** e.g. the sign in with Google popup was blocked by the browser. */
export class PopupBlockedError extends Error {
    constructor() {
        super("Popup blocked");
        Object.setPrototypeOf(this, PopupBlockedError.prototype);
        Error.captureStackTrace?.(this, PopupBlockedError);
        this.name = this.constructor.name;
    }
}

/** Used to stop sign in promise execution when user cancels a sign in with
 * popup, e.g. Google sign in. */
export class PopupClosedByUserError extends Error {
    constructor() {
        super("Popup closed by user");
        Object.setPrototypeOf(this, PopupClosedByUserError.prototype);
        Error.captureStackTrace?.(this, PopupClosedByUserError);
        this.name = this.constructor.name;
    }
}

/** If someone outside the organization tries to sign in with Google,
 * there is no risk of email enumeration, so we can give them that information
 * instead of saying "wrong password".
 */
export class OutsideOrganizationError extends Error {
    constructor() {
        super("No perteneces a la organización");
        Object.setPrototypeOf(this, OutsideOrganizationError.prototype);
        Error.captureStackTrace?.(this, OutsideOrganizationError);
        this.name = this.constructor.name;
    }
}

/** @deprecated - Use `Organization` instead */
export interface WorkspaceOverview {
    companyLogoUrl: string;
    /** e.g. "Mall Plaza". */
    companyName: string;
    /** e.g. "mycompany". */
    subdomain: string;
    backendVersion: BackendVersion;
}

/** @deprecated - Use `Organization` instead */
export interface WorkspaceDetail extends WorkspaceOverview {
    /** @see AuthenticatedUser */
    authTenantId: string;
    signInMethods: SignInMethod[];
}

export type SignInMethod = (typeof SignInMethodPseudoEnum)[keyof typeof SignInMethodPseudoEnum];
export const SignInMethod = SignInMethodPseudoEnum;

/** See https://cloud.google.com/identity-platform/docs/admin/email-enumeration-protection
 * for session. */
export type EnumerationSafeSignInMethod = Exclude<
    SignInMethod,
    typeof SignInMethod.EMAIL_LINK | typeof SignInMethod.EMAIL_PASSWORD
>;

export function isEnumerationSafeSignInMethod(
    signInMethod: SignInMethod,
): signInMethod is EnumerationSafeSignInMethod {
    return signInMethod !== SignInMethod.EMAIL_LINK && signInMethod !== SignInMethod.EMAIL_PASSWORD;
}

/** https://github.com/aimmanager/gargamel-api-docs/blob/main/Services/Auth.md#receive-magic-code */
export interface ReceiveMagicCodeRequest {
    email: string;
    magicCode: string;
}

/** https://github.com/aimmanager/gargamel-api-docs/blob/main/Services/Auth.md#receive-magic-code */
export interface ReceiveMagicCodeResponse {
    subdomain: string;
    firebaseTenantId: string | null;
    firebaseCustomToken: string | null;
}

export function sReceiveMagicCodeResponse() {
    return type({
        subdomain: string(),
        firebase_tenant_id: string(),
        firebase_custom_token: string(),
    });
}
