import { assignHeaders, Client } from "../../modules/client/client";
import { parsedEnv } from "../../utils/parsedEnv";
import { firebaseAuthService } from "../services/auth/implementations/firebase";
import { isAuthenticated } from "../../modules/auth/authContext";

export const notificationsClient = new Client(`${parsedEnv.VITE_SERVER_NOTIFICATIONS}`, {
    intercept: async (request, fetchData) => {
        if (isAuthenticated())
            assignHeaders(request.headers, {
                Authorization: `Bearer ${await firebaseAuthService.getIdToken()}`,
            });
        return fetchData(request);
    },
});
