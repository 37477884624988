import { Describe, integer, string, type } from "superstruct";
import { SignInMethod } from "../auth/interface";
import { ServiceQuery } from "../../utils";
/** https://github.com/aimmanager/gargamel-api-docs/blob/main/Services/Auth.md#organization */
export type Organization = {
    id: string;
    name: string;
    subdomain: string;
    logoUrl: string;
    firebaseTenantId: string | null;
    signInMethods: SignInMethod[];
};

export type OrganizationListItem = {
    id: number;
    subdomain: string;
    name: string;
    firebase_tenant_id: string;
};

export function sOrganizationListItem(): Describe<OrganizationListItem> {
    return type({
        id: integer(),
        subdomain: string(),
        name: string(),
        firebase_tenant_id: string(),
    });
}

export interface OrganizationService {
    /** https://github.com/aimmanager/gargamel-api-docs/blob/main/Services/Auth.md#get-current-organization */
    getCurrentOrganization(): Promise<Organization>;

    /** https://github.com/aimmanager/gargamel-api-docs/blob/main/Services/Auth.md#get-organization-by-subdomain
     *
     * Returns null if there is no organization matching the subdomain. */
    getOrganizationBySubdomain: ServiceQuery<[subdomain: string], Organization | null>;
}
