import { P } from "../../../utils/typography";
import { Tab } from "../../ui/Tabs";
import AccordionCard, { AccordionCardItem } from "../../ui/AccordionCard";
import { TaskCard } from "./TaskCard";
import { Button } from "../../ui/components";
import { ModalController } from "../../ui/Modal";
import { CircleNumber } from "../../ui/CircleNumber";
import TempImage from "../../../assets/imgs/download.jpeg";

const fakeString =
    "Asegúrate de tener a mano bolsas de basura resistentes, guantes desechables y, si es necesario, una carretilla o un";

type Props = {
    modalController: ModalController<void, void>;
};
export function TaskTab(props: Props) {
    return (
        <Tab title={"Tareas"}>
            <div class={"mt-3 space-y-3"}>
                <AccordionCard>
                    <AccordionCardItem title={"Detalles"} isOpen>
                        <P class={"text-default"}>{fakeString.substring(0, 115).trimEnd()}...</P>
                        <Button
                            bgStyle={"text-only"}
                            class={"ml-auto"}
                            onClick={() => props.modalController.open()}
                        >
                            <span>
                                <i class="fas fa-plus"></i>
                            </span>
                            Leer más
                        </Button>
                    </AccordionCardItem>

                    <AccordionCardItem
                        title={
                            <P class={"!mb-0 flex items-center gap-x-3"}>
                                <span>Tareas</span>
                                <CircleNumber number={5} />
                            </P>
                        }
                        isOpen
                    >
                        <TaskCard
                            isReady
                            isOptional
                            taskType={"Confirmación manual"}
                            title={"¿Tienes los implementos necesarios sacar la basura?"}
                            description={"Se solicita confirmación manual."}
                        />
                        <TaskCard
                            isReady={false}
                            isOptional={false}
                            taskType={"Tomar y subir foto"}
                            title={"Tome una foto de la basura"}
                            description={"Tome una foto de la basura acumulada"}
                        />
                        <TaskCard
                            isReady={false}
                            isOptional={false}
                            taskType={"Tomar y subir foto"}
                            title={"Tome una foto de la basura depositada en el punto de acopio"}
                            description={"Tome una foto del punto de acopio"}
                        />
                    </AccordionCardItem>

                    <AccordionCardItem
                        title={
                            <P class={"!mb-0 flex items-center gap-x-3"}>
                                <span>Tareas resueltas</span>
                                <CircleNumber number={2} />
                            </P>
                        }
                        isOpen
                    >
                        <TaskCard
                            isReady
                            isOptional={false}
                            isDone
                            taskType={"Confirmación manual"}
                            images={[TempImage, TempImage]}
                            title={"¿Tienes los implementos necesarios sacar la basura?"}
                            description={"Se solicita confirmación manual."}
                        />
                        <TaskCard
                            isReady={false}
                            isOptional={false}
                            isDone
                            taskType={"Tomar y subir foto"}
                            images={[TempImage, TempImage, TempImage]}
                            title={"Tome una foto de la basura"}
                            description={"Tome una foto de la basura acumulada"}
                        />
                    </AccordionCardItem>
                </AccordionCard>
            </div>
        </Tab>
    );
}
