import { create, Describe, StructError } from "superstruct";
import { fetchDataFromMockBackend } from "./backendMocks";
import { parsedEnv } from "../../utils/parsedEnv";
import { Json } from "../../api/utils";
import { firebaseAuthService } from "../../api/services/auth/implementations/firebase";
import { isAuthenticated } from "../auth/authContext";

/** Used to modify the request and/or add custom logic like refresh token.
 * You can mutate the request directly without problems. */
type Interceptor = (
    request: Request,
    fetchData: (request: Request) => Promise<Json | undefined>,
) => Promise<Json | undefined>;

export class Client {
    constructor(
        private readonly urlPrefix: string,
        private readonly options?: Partial<{
            readonly mock: boolean;
            readonly intercept: Interceptor;
        }>,
    ) {}

    get(path: string): RequestBuilder {
        return this.makeRequestBuilder("GET", path);
    }

    post(path: string): RequestBuilder {
        return this.makeRequestBuilder("POST", path);
    }

    put(path: string): RequestBuilder {
        return this.makeRequestBuilder("PUT", path);
    }

    patch(path: string): RequestBuilder {
        return this.makeRequestBuilder("PATCH", path);
    }

    delete(path: string): RequestBuilder {
        return this.makeRequestBuilder("DELETE", path);
    }

    private makeRequestBuilder(method: string, path: string) {
        return new RequestBuilder({
            method,
            path,
            urlPrefix: this.urlPrefix,
            intercept: this.options?.intercept ?? ((request, fetchData) => fetchData(request)),
            ...this.options,
        });
    }
}

class RequestBuilder {
    constructor(
        private readonly options: {
            readonly method: string;
            readonly urlPrefix: string;
            readonly path: string;
            readonly mock?: boolean;
            readonly intercept: Interceptor;
        },
    ) {}

    private currentQueryParams: Record<string, string | number> = {};
    private currentHeaders = new Headers();
    private currentBodyInit: BodyInit | undefined = undefined;

    query(params: Record<string, string | number>): this {
        Object.assign(this.currentQueryParams, params);
        return this;
    }

    headers(headers: HeadersInit): this {
        assignHeaders(this.currentHeaders, headers);
        return this;
    }

    sendFormUrlEncoded(body: Record<string, string>): this {
        assignHeaders(this.currentHeaders, { "Content-Type": "application/x-www-form-urlencoded" });
        this.currentBodyInit = new URLSearchParams(body);
        return this;
    }

    sendJson<TBody extends Json>(body: TBody): this {
        assignHeaders(this.currentHeaders, { "Content-Type": "application/json" });
        this.currentBodyInit = JSON.stringify(body);
        return this;
    }

    async receive<TResponseBody>(responseSchema: Describe<TResponseBody>): Promise<TResponseBody> {
        const { request, responseBody } = await this.fetch();
        return validateResponse(responseBody, responseSchema, { request });
    }

    /** Fetch the request, assuming the response will be JSON.
     * Throws an Error on an empty response.
     * @remarks - To simplify the implementation, non-empty responses
     * that are also non-JSON are returned as a string. */
    async receiveJson(): Promise<Json> {
        const { request, responseBody } = await this.fetch();
        if (responseBody === undefined)
            throw new Error(`${request.method} ${request.url} returned empty response.`);
        return responseBody;
    }

    /** Fetch the request, assuming the response will be empty.
     * Triggers a warning on a non-empty response. */
    async receiveNothing(): Promise<void> {
        const { request, responseBody } = await this.fetch();
        if (responseBody)
            console.warn(
                `${request.method} ${request.url} actually returned a non-empty response.`,
            );
    }

    private async fetch(): Promise<{ request: Request; responseBody: Json | undefined }> {
        const request = new Request(
            makeAbsoluteUrl(this.options.urlPrefix, this.options.path, this.currentQueryParams),
            {
                method: this.options.method,
                headers: this.currentHeaders,
                body: this.currentBodyInit,
            },
        );
        const responseBody = await this.options.intercept(request, request =>
            fetchData(request, !!this.options.mock),
        );
        return { request, responseBody };
    }
}

/** Like Object.assign, but for Headers. Mutates `target`. */
export function assignHeaders(target: Headers, source: HeadersInit | undefined): void {
    new Headers(source).forEach((value: string, key: string) => {
        target.set(key, value);
    });
}

/** Validates the `responseData` against the `responseSchema`.
 * If valid, returns the `responseData` cast to the appropriate type.
 * If invalid, throws a `StructError`.
 * The `context` parameter is used to provide debug info to the developer. */
function validateResponse<TResponseData>(
    responseData: unknown,
    responseSchema: Describe<TResponseData>,
    context: { request: Request },
): TResponseData {
    try {
        return create(responseData, responseSchema);
    } catch (error) {
        if (error instanceof StructError) {
            console.error(error);
            console.debug({ request: context.request, responseData });
        }
        throw error;
    }
}

function makeAbsoluteUrl(
    urlPrefix: string,
    path: string,
    queryParams: Record<string, string | number>,
): string {
    const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join("&");
    let url = urlPrefix + path;
    // Note: if the url ends with /, using /api/endpoint/?query=param also works.
    if (queryString) url += "?" + queryString;
    return url;
}

/** fetch, but returns just the data (e.g. JSON) instead of the whole Response object. */
async function fetchData(request: Request, mock: boolean): Promise<Json | undefined> {
    if (mock) {
        return await fetchDataFromMockBackend(request);
    } else {
        return await fetchDataFromRealBackend(request);
    }
}

async function fetchDataFromRealBackend(request: Request): Promise<Json | undefined> {
    const response = await fetch(request);
    const body = await parseResponse(response);
    if (!response.ok) {
        console.debug({
            requestHeaders: Object.fromEntries(request.headers.entries()),
            responseBody: body,
        });
        throw new BadResponseError(
            `[${response.status}] ${request.method} ${request.url} ${JSON.stringify(
                body,
                null,
                4,
            )}`,
            response,
            body,
        );
    }

    return body;
}

/** Returns the response body as an object if possible, specifically:
 * - a plain object, if the response is JSON,
 * - undefined, if the response is empty,
 * - or a string, if the response has non-JSON content (e.g. HTML).
 */
async function parseResponse(response: Response): Promise<Json | undefined> {
    const text = await response.text();
    try {
        return text ? JSON.parse(text) : undefined;
    } catch (error) {
        if (error instanceof SyntaxError) return text;
        else throw error;
    }
}

export class BadResponseError extends Error {
    constructor(
        message: string,
        public readonly response: Response,
        public readonly body: unknown,
    ) {
        super(message);
        Object.setPrototypeOf(this, BadResponseError.prototype);
        Error.captureStackTrace?.(this, BadResponseError);
        this.name = this.constructor.name;
    }
}

export class EmailAlreadyInUseError extends Error {
    constructor() {
        super("Email already in use");
        Object.setPrototypeOf(this, EmailAlreadyInUseError.prototype);
        Error.captureStackTrace?.(this, EmailAlreadyInUseError);
        this.name = this.constructor.name;
    }
}
/** The top-level domain of the current environment. */
export const tld: "red" | "blue" | "pink" | "io" = "red";
export const deviceClient = new Client(`https://device.aimmanager.${tld}`);
export const atlasClient = new Client("/api", {
    mock: parsedEnv.VITE_MOCK_ATLAS,
    intercept: async (request, fetchData) => {
        if (isAuthenticated())
            assignHeaders(request.headers, {
                Authorization: `Bearer ${await firebaseAuthService.getIdToken()}`,
                ...atlasDummyHeaders,
            });
        return fetchData(request);
    },
});

/** Some Atlas endpoints arbitrarily require some headers to be present even
 * if they seem to be unused. Instead of having to memorize which endpoints
 * require which headers, let's send every header that may be needed on every
 * request to Atlas. Not sending the required headers makes Atlas to respond
 * with error 400. */
const atlasDummyHeaders = {
    "x-aim-username": "",
    "x-aim-account-host": "",
    "x-aim-user-actions": "",
};

export const workflowClient = new Client(parsedEnv.VITE_SERVER_WORKFLOW, {
    intercept: async (request, fetchData) => {
        if (isAuthenticated())
            assignHeaders(request.headers, {
                Authorization: `Bearer ${await firebaseAuthService.getIdToken()}`,
            });
        return fetchData(request);
    },
});
