import {
    AudienceFunction,
    AudienceListItem,
    AudiencePosition,
    AudienceResult,
    AudienceService,
    GroupType,
} from "../interface";
import { makeServiceQuery } from "../../../utils";
import { array, Infer, integer, nullable, number, string, type } from "superstruct";
import { personClient } from "../../../clients/paulsen";

export default class V3AudienceService implements AudienceService {
    listGroupTypes = makeServiceQuery({
        fetchJson: async () => personClient.get("/group_types").receiveJson(),
        responseSchema: array(sV3GroupType()),
        deserialize: array => array.map(deserializeGroupType),
    });

    listAudiences = makeServiceQuery({
        fetchJson: async () => personClient.get("/audiences/").receiveJson(),
        responseSchema: array(sV3AudienceListItem()),
        deserialize: array => array.map(deserializeAudienceListItem),
    });

    audienceFunctions = makeServiceQuery({
        fetchJson: async () => personClient.get("/audience_functions").receiveJson(),
        responseSchema: array(sV3AudienceFunction()),
        deserialize: array => array.map(deserializeAudienceFunction),
    });

    audienceResultList = makeServiceQuery({
        fetchJson: async (audienceId: string) =>
            personClient.get(`/audiences/${audienceId}/result`).receiveJson(),
        responseSchema: array(sV3AudienceResult()),
        deserialize: array => array.map(deserializeAudienceResult),
    });

    createAudience = async (payload: V3CreateAudiencePayload) => {
        const response = await personClient
            .post("/audiences/")
            .sendJson(payload)
            .receive(sV3AudienceListItem());
        return deserializeAudienceListItem(response);
    };

    listGroups = makeServiceQuery({
        fetchJson: async () => personClient.get("/groups").receiveJson(),
        responseSchema: array(sV3AudienceGroup()),
        deserialize: array => array.map(deserializeAudienceGroup),
    });

    getVariables = makeServiceQuery({
        fetchJson: async () => personClient.get(`/variables/`).receiveJson(),
        responseSchema: array(sV3AudienceVariable()),
        deserialize: array => array.map(deserializeAudienceVariable),
    });

    listPositions = makeServiceQuery({
        fetchJson: async () => personClient.get("/positions").receiveJson(),
        responseSchema: array(sV3AudiencePosition()),
        deserialize: array => array.map(deserializeAudiencePosition),
    });

    createVariable = async (payload: V3CreateVariablePayload) => {
        return await personClient
            .post("/variables/")
            .sendJson(payload)
            .receive(sV3AudienceVariable());
    };
}

function deserializeAudiencePosition(payload: V3AudiencePosition): AudiencePosition {
    return {
        ...payload,
    };
}
type V3GroupType = Infer<ReturnType<typeof sV3GroupType>>;

function sV3GroupType() {
    return type({
        id: integer(),
        name: string(),
        // organization: integer(), // we don't care
    });
}

type V3AudienceListItem = Infer<ReturnType<typeof sV3AudienceListItem>>;
function sV3AudienceListItem() {
    return type({
        id: number(),
        name: string(),
        expression: string(),
    });
}

type V3AudienceFunction = Infer<ReturnType<typeof sV3AudienceFunction>>;
function sV3AudienceFunction() {
    return type({
        name: string(),
        arg: string(),
    });
}
function deserializeGroupType(payload: V3GroupType): GroupType {
    return {
        id: payload.id.toString(),
        name: payload.name,
    };
}

function deserializeAudienceListItem(payload: V3AudienceListItem): AudienceListItem {
    return {
        ...payload,
        id: payload.id.toString(),
    };
}

function deserializeAudienceFunction(payload: V3AudienceFunction): AudienceFunction {
    return {
        ...payload,
    };
}

export type V3AudienceResult = Infer<ReturnType<typeof sV3AudienceResult>>;
export type V3AudiencePosition = Infer<ReturnType<typeof sV3AudiencePosition>>;
function sV3AudiencePosition() {
    return type({
        id: integer(),
        name: string(),
        organization: integer(),
        left_parent: nullable(integer()),
        right_parent: nullable(integer()),
    });
}
function sV3AudienceResult() {
    return type({
        id: integer(),
        first_name: string(),
        last_name: string(),
        email: string(),
        organization: integer(),
    });
}
function deserializeAudienceResult(payload: V3AudienceResult): AudienceResult {
    return {
        ...payload,
    };
}
type V3CreateVariablePayload = Infer<ReturnType<typeof sV3AudienceVariablePayload>>;
type V3Variable = Infer<ReturnType<typeof sV3AudienceVariable>>;
type V3CreateAudiencePayload = Infer<ReturnType<typeof sV3CreateAudiencePayload>>;
function deserializeAudienceVariable(payload: V3Variable) {
    return {
        ...payload,
    };
}
function sV3AudienceVariable() {
    return type({
        id: integer(),
        name: string(),
        function: string(),
        args: array(number()),
        audience: number(),
    });
}
function sV3AudienceVariablePayload() {
    return type({
        audience: integer(),
        name: string(),
        function: string(),
        args: array(number()),
    });
}
function sV3CreateAudiencePayload() {
    return type({
        name: string(),
        expression: string(),
    });
}
// function sV3CreateVariablePayload() {
//     return type({
//         audience: number(),
//         name: string(),
//         function: string(),
//         args: array(number()),
//     });
// }

type V3AudienceGroup = Infer<ReturnType<typeof sV3AudienceGroup>>;
function sV3AudienceGroup() {
    return type({
        id: integer(),
        name: string(),
        type: integer(),
    });
}

function deserializeAudienceGroup(payload: V3AudienceGroup) {
    return {
        ...payload,
    };
}
