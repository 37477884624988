import ActivityItemList from "./ActivityItemList";
import ActivityHeader from "./ActivityHeader";
import ActivityBubbleMenu from "./ActivityBubbleMenu";
import Warning from "../ui/Warning";
import { useResponsiveBreakpoints } from "../../utils/useResponsiveBreakpoints";
import { Show } from "solid-js";

export default function ActivitiesPage() {
    const { sm } = useResponsiveBreakpoints();

    return (
        <div class={"relative min-h-screen md:p-4"}>
            <Warning>Esto es una maqueta incompleta de la feature.</Warning>
            <ActivityHeader />
            <ActivityItemList />
            <Show when={!sm()}>
                <ActivityBubbleMenu />
            </Show>
        </div>
    );
}
