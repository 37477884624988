import { For, Show, Suspense } from "solid-js";
import { PageWrapper } from "../ui/pageWrappers";
import { FormWrapper } from "../forms/FormWrapper";
import SubmitButton from "../forms/SubmitButton";
import TextField from "../forms/fields/TextField";
import { createWorkflowMutation } from "../../api/services/workflow/mutations";
import { Workflow } from "../../api/services/workflow/interface";
import { createWorkflowListQuery } from "../../api/services/workflow/queries";
import { BorderedCard } from "../ui/cards";
import { useNavigate } from "@solidjs/router";
import { P } from "../../utils/typography";
import { useLocale } from "../i18n/context";
import { Tab, Tabs } from "../ui/Tabs";
import WorkflowExecutionList from "./WorkflowExecutionList";
import { WorkflowStatus } from "./WorkflowDetailPage";
import { Entry, RecordTable } from "../ui/RecordTable";
import { GenericSuspenseFallback } from "../ui/skeletons";

export default function WorkflowsPage() {
    return (
        <PageWrapper>
            <Tabs>
                <Tab title="Workflows">
                    <WorkflowList />
                    <CreateWorkflowForm />
                </Tab>
                <Tab title="Ejecuciones">
                    <WorkflowExecutionList />
                </Tab>
            </Tabs>
        </PageWrapper>
    );
}

function WorkflowList() {
    const workflowListQuery = createWorkflowListQuery();

    return (
        <Suspense fallback={<GenericSuspenseFallback />}>
            <Show when={workflowListQuery.data}>
                {workflowList => (
                    <div class={"my-3 grid grid-cols-1 flex-wrap gap-3 md:grid-cols-12"}>
                        <For each={workflowList()} fallback={<NoWorkflows />}>
                            {workflow => <WorkflowItem workflow={workflow} />}
                        </For>
                    </div>
                )}
            </Show>
        </Suspense>
    );
}

function NoWorkflows() {
    const [locale] = useLocale();

    return <P>{locale().workflows.noWorkflows}</P>;
}

function WorkflowItem(props: { workflow: Workflow }) {
    const navigate = useNavigate();
    const [locale] = useLocale();

    return (
        <BorderedCard
            onClick={() => navigate(`/workflows/${props.workflow.id}`)}
            class={
                "col-span-1 cursor-pointer border-2 md:col-span-4 " +
                (!props.workflow.isActive && "border-dashed")
            }
        >
            <div class="float-right">
                <WorkflowStatus isActive={props.workflow.isActive} />
            </div>
            <P class={"text-h5 font-normal"}>{props.workflow.name}</P>
            <RecordTable>
                <Entry
                    name="Creado"
                    value={Intl.DateTimeFormat(locale().codeWithCountry, {
                        dateStyle: "long",
                        timeStyle: "short",
                    }).format(props.workflow.createdAt)}
                />
                <Entry
                    name="Actualizado"
                    value={Intl.DateTimeFormat(locale().codeWithCountry, {
                        dateStyle: "long",
                        timeStyle: "short",
                    }).format(props.workflow.createdAt)}
                />
            </RecordTable>
        </BorderedCard>
    );
}

function CreateWorkflowForm() {
    const workflowMutation = createWorkflowMutation();
    const [locale] = useLocale();
    const t = () => locale().workflows;

    async function handleSubmit(formValues: { name: string }): Promise<void> {
        await workflowMutation.mutateAsync(formValues.name);
    }

    return (
        <FormWrapper onSubmit={handleSubmit} class={"space-y-3"}>
            <TextField name="name" label={t().name} />
            <SubmitButton submittingText="creatingWorkfLOW">{t().createWorkflow}</SubmitButton>
        </FormWrapper>
    );
}
