import {
    AuthService,
    BackendVersion,
    PopupClosedByUserError,
    ReceiveMagicCodeRequest,
    ReceiveMagicCodeResponse,
    WorkspaceOverview,
    WrongPasswordError,
} from "../interface";
import testIncLogo from "../../../../assets/imgs/test-inc.png";
import johnnyTestPicture from "../../../../assets/imgs/johnny-test.jpg";
import { dispatchSignInEvent, dispatchSignOutEvent } from "../../../../modules/auth/authContext";
import { AuthenticatedUser } from "../../../../modules/auth/useAuthReturn";
import { sleep } from "../../../../utils/mocks";
import { Organization } from "../../organization/interface";
import myCompanyLogo from "../../../../assets/imgs/logo-mycompany-default.png";
import { mockOrganizations } from "../../organization/implementations/mock";

export default class MockAuthService implements AuthService {
    listWorkspaces = async (_email: string): Promise<WorkspaceOverview[]> => {
        return [
            {
                companyName: "MyCompany",
                companyLogoUrl: myCompanyLogo,
                subdomain: "mycompany",
                backendVersion: BackendVersion.Atlas,
            },
            {
                companyName: "TEST Inc.",
                companyLogoUrl: testIncLogo,
                subdomain: "test",
                backendVersion: BackendVersion.Atlas,
            },
        ];
    };

    signInWithEmailAndPassword = async (
        organization: Organization,
        email: string,
        password: string,
    ): Promise<void> => {
        if (password === "wrong") throw new WrongPasswordError();
        dispatchSignInEvent(this.mockUser(email, organization));
    };

    public simulatePopupClosed = false;

    signInWithGoogle = async (
        organization: Organization,
        email: string | undefined,
    ): Promise<void> => {
        if (this.simulatePopupClosed) throw new PopupClosedByUserError();
        dispatchSignInEvent(this.mockUser(email, organization));
    };

    sendPasswordResetEmail = async (_organization: Organization, _email: string): Promise<void> => {
        // Do nothing
    };

    getBearerToken = async (): Promise<string> => {
        return "m0ck-b3ar3r-t0k3n";
    };

    signOut = async (): Promise<void> => {
        dispatchSignOutEvent();
    };

    sendMagicCode = async (_email: string): Promise<void> => {
        await sleep(1000);
    };

    receiveMagicCode = async (
        request: ReceiveMagicCodeRequest,
    ): Promise<ReceiveMagicCodeResponse | null> => {
        await sleep(1000);
        if (request.magicCode === "123456")
            return {
                subdomain: mockOrganizations[0].subdomain,
                firebaseTenantId: mockOrganizations[0].firebaseTenantId,
                firebaseCustomToken: null,
            };
        else return null;
    };

    sendSigninLinkToEmail = async (_organization: Organization, _email: string): Promise<void> => {
        // Do nothing
    };

    sendInvite = async (_email: string): Promise<void> => {
        // Do nothing
    };

    async isSignInWithEmailLink(_link: string): Promise<boolean> {
        return true;
    }

    async signInWithEmailLink(body: { email: string; link: string }) {
        dispatchSignInEvent(this.mockUser(body.email, mockOrganizations[0]));
    }

    public simulateUnavailableProfilePicture = false;

    private mockUser(email: string | undefined, organization: Organization): AuthenticatedUser {
        return {
            id: "j06nny-t35t",
            email: email ?? "johnny.test@example.com",
            name: "Johnny Test",
            firebaseTenantId: organization.firebaseTenantId,
            picture: this.simulateUnavailableProfilePicture ? null : johnnyTestPicture,
            backendVersion: BackendVersion.Atlas,
        };
    }
}
