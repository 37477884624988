import { useLocale } from "../../../i18n/context";
import { ParentProps, Show } from "solid-js";
import { ErrorMessage } from "./ErrorMessage";
import { FieldProps } from "./types";
import { FieldController } from "../../state";

const labelClass = "font-normal";

type LabelLayoutProps<TElement> = ParentProps<FieldProps<unknown, TElement>> & {
    /** The id of the HTML input that this layout is wrapping, used to focus
     * the input when clicking the label.
     *
     * @example
     * function MyField(props: FieldProps<...>) {
     *    const field = createField(...);
     *    const inputId = createUniqueId();
     *
     *    return (
     *        <LabelAbove {...props} field={field} inputId={inputId}>
     *            ...
     *                <input {...getCommonInputProps(inputId, props)} />
     *            ...
     *        </LabelAbove>
     *    );
     * }
     *
     * @remarks
     * Fields with multiple inputs, like radio buttons, require creating a
     * unique `inputId` for each input, or clicking the labels will always
     * focus the first option.
     */
    field: FieldController<unknown>;
    inputId: string;
    parentClass?: string;
    errorClass?: string;
};

export function LabelAbove<TElement>(props: LabelLayoutProps<TElement>) {
    const [locale] = useLocale();

    return (
        <div class={`flex w-full flex-col ${props.parentClass ?? ""}`}>
            <label for={props.inputId} class={`${labelClass} ${props.labelClass}`}>
                {props.label} <Show when={props.optional}> ({locale().personalData.optional})</Show>
            </label>
            {props.children}
            <ErrorMessage field={props.field} class={props.errorClass} />
        </div>
    );
}

export function LabelAfter<TElement>(props: LabelLayoutProps<TElement>) {
    return (
        <div class={`flex items-center gap-1 ${props.parentClass ?? ""}`}>
            {props.children}
            <label for={props.inputId} class={`${labelClass} ${props.labelClass}`}>
                {props.label}
            </label>
        </div>
    );
}

export function LabelParent<TElement>(props: LabelLayoutProps<TElement>) {
    return (
        <label
            for={props.inputId}
            class={`flex items-center gap-1 ${labelClass} ${props.labelClass}`}
        >
            <span>{props.label}</span>
            {props.children}
        </label>
    );
}
