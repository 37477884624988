import {
    AuthService,
    ReceiveMagicCodeRequest,
    ReceiveMagicCodeResponse,
    sReceiveMagicCodeResponse,
    WorkspaceOverview,
} from "../interface";
import { firebaseAuthService } from "./firebase";
import { any } from "superstruct";

import { paulsenSignOut, publicAuthClient } from "../../../clients/paulsen";
export default class V3AuthService implements AuthService {
    listWorkspaces = async (_email: string): Promise<WorkspaceOverview[]> => {
        throw new Error("Not implemented");
    };

    signInWithEmailAndPassword = firebaseAuthService.signInWithEmailAndPassword;

    signInWithGoogle = firebaseAuthService.signInWithGoogle;

    sendPasswordResetEmail = firebaseAuthService.sendPasswordResetEmail;

    signOut = async (): Promise<void> => {
        await firebaseAuthService.signOut();
        paulsenSignOut();
    };

    getCurrentWorkspace = async (): Promise<WorkspaceOverview> => {
        throw new Error("Not implemented");
    };

    sendMagicCode = async (email: string): Promise<void> => {
        return publicAuthClient.post("/magic_codes/send/").sendJson({ email }).receive(any());
    };

    receiveMagicCode = async (
        request: ReceiveMagicCodeRequest,
    ): Promise<ReceiveMagicCodeResponse | null> => {
        try {
            const res = await publicAuthClient
                .post("/magic_codes/check/")
                .sendJson({
                    email: request.email,
                    code: request.magicCode.replaceAll("-", ""),
                })
                .receive(sReceiveMagicCodeResponse());

            return {
                firebaseCustomToken: res.firebase_custom_token,
                firebaseTenantId: res.firebase_tenant_id == "" ? null : res.firebase_tenant_id,
                subdomain: res.subdomain,
            };
        } catch (err) {
            return null;
        }
    };

    sendSigninLinkToEmail = firebaseAuthService.sendSigninLinkToEmail;
    sendInvite = firebaseAuthService.sendInvite;
    isSignInWithEmailLink = firebaseAuthService.isSignInWithEmailLink;
    signInWithEmailLink = firebaseAuthService.signInWithEmailLink;
}
