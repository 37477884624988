import { BorderedCard } from "../../ui/cards";
import { Badge } from "../../ui/components";
import { For, ParentProps, Show } from "solid-js";
import { P } from "../../../utils/typography";
import { CircleNumber } from "../../ui/CircleNumber";

type FakeType = {
    title: string;
    description: string;
    isReady: boolean;
    taskType: string;
    isOptional: boolean;
    isDone?: boolean;
    images?: string[];
};
export function TaskCard(props: ParentProps<FakeType>) {
    return (
        <BorderedCard>
            <div class={"flex gap-x-3"}>
                <div>
                    <div
                        class={
                            "flex min-h-8 min-w-8 items-center justify-center rounded-full bg-light-gray-100"
                        }
                        classList={{
                            "bg-success-100": props.isDone,
                        }}
                    >
                        {props.isReady ? (
                            <i class={`fas fa-check ${props.isDone ? "text-success-600" : ""}`}></i>
                        ) : (
                            <i
                                class={`fas fa-camera ${props.isDone ? "text-success-600" : ""}`}
                            ></i>
                        )}
                    </div>
                </div>
                <div>
                    <P class={`!mb-0 text-default font-bold ${props.isDone ? "opacity-50" : ""}`}>
                        {props.title}
                    </P>
                    <P class={"!mb-0 text-sm"}>{props.description}</P>
                    <div class={"mt-1 flex justify-between"}>
                        <Badge class={"w-fit border border-light-gray-200 !bg-light-gray-50"}>
                            {props.taskType}
                        </Badge>
                        {props.isOptional && (
                            <Badge class={"w-fit border border-light-gray-600 bg-transparent"}>
                                Opcional
                            </Badge>
                        )}
                    </div>
                </div>
            </div>
            <div>
                <Show when={props.isDone}>
                    <P class={"mt-3 text-center font-medium text-success-600"}>
                        Finalizada el 16 de feb, 22:47
                    </P>
                    <div class={"rounded-xs bg-light-gray-200 p-2"}>
                        <div class={"flex justify-between"}>
                            <P>Respuesta</P>
                            <CircleNumber number={1} />
                        </div>
                        <div class={"grid grid-cols-12 gap-x-2"}>
                            <For each={props.images}>
                                {image => {
                                    return (
                                        <img
                                            src={image}
                                            alt={"image"}
                                            class={"col-span-4 h-auto rounded-md"}
                                        />
                                    );
                                }}
                            </For>
                        </div>
                    </div>
                </Show>
            </div>
        </BorderedCard>
    );
}
