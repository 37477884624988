import { Tab, Tabs } from "../ui/Tabs";
import { PageWrapper } from "../ui/pageWrappers";
import mockCalendar from "./mockCalendar.ics.txt?raw";
import { H1 } from "../../utils/typography";
import ICAL from "ical.js";
import { JsonDebug } from "../../utils/debug";
import { createMemo, createSignal, ErrorBoundary, JSX, onMount, ParentProps, Show } from "solid-js";
import { fireAndForget } from "../../utils/async";
import _ from "lodash";
import { createCalendarQuery } from "../../api/services/task-manager/queries";
import { ErrorBlock } from "../../utils/GenericErrorBoundary";
import { Calendar as FCCalendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import rrulePlugin from "@fullcalendar/rrule";
import { createRef } from "../../utils/reactRefs";
import { useNavigate } from "@solidjs/router";
import { Activity } from "../../api/services/task-manager/interface";

export default function ActivitiesPlaygroundPage() {
    const [ics, setIcs] = createSignal(mockCalendar);
    const jCal = createMemo(() => ICAL.parse(ics()));
    const vCal = createMemo(() => decomposeCalendar(new ICAL.Component(jCal())));
    const jCal2 = createMemo(() => composeCalendar(vCal()).toJSON());
    const ics2 = createMemo(() => {
        try {
            return ICAL.stringify(jCal2());
        } catch (error) {
            console.debug(jCal());
            console.debug(jCal2());
            console.error(error);
            if (error instanceof Error) return error.message;
            else throw error;
        }
    });

    const importCalendar = async (file: File) => {
        setIcs(await file.text());
    };

    // prettier-ignore
    const titles = {
        ics: <span>iCalendar <Code>.ics</Code> file</span>,
        jCal: <span><Code>jCal</Code> JSON representation</span>,
        vCal: <span>Parsed <Code>VTodo</Code>s</span>,
        jCal2: <span>De <Code>VTodo</Code>s a <Code>jCal</Code></span>,
        ics2: <span>De <Code>jCal</Code> a <Code>.ics</Code></span>,
    };

    const calendarQuery = createCalendarQuery();

    return (
        <PageWrapper>
            <div class="flex gap-10">
                <H1>Activities Playground</H1>
                <input
                    type="file"
                    onChange={event => {
                        const file = event.currentTarget.files?.[0];
                        if (!file) return;
                        fireAndForget(importCalendar(file));
                    }}
                />
            </div>
            <Tabs>
                <Tab title="De iCalendar a TODOs">
                    <PaneGrid>
                        <ScrollablePane title={titles.ics}>
                            <pre>{ics()}</pre>
                        </ScrollablePane>
                        <ScrollablePane title={titles.jCal}>
                            <JsonDebug value={jCal()} />
                        </ScrollablePane>
                        <ScrollablePane title={titles.vCal}>
                            <JsonDebug value={vCal().todos} />
                        </ScrollablePane>
                    </PaneGrid>
                </Tab>
                <Tab title="De TODOs a iCalendar">
                    <PaneGrid>
                        <ScrollablePane title={titles.vCal}>
                            <JsonDebug value={vCal().todos} />
                        </ScrollablePane>
                        <ScrollablePane title={titles.jCal2}>
                            <JsonDebug value={jCal2()} />
                        </ScrollablePane>
                        <ScrollablePane title={titles.ics2}>
                            <pre>{ics2()}</pre>
                        </ScrollablePane>
                    </PaneGrid>
                </Tab>
                <Tab title="fullcalendar">
                    <ErrorBoundary fallback={error => <ErrorBlock error={error} />}>
                        <Show when={calendarQuery.data}>
                            {data => <FullCalendarView calendar={parseCalendar(data().calendar)} />}
                        </Show>
                    </ErrorBoundary>
                </Tab>
                <Tab title="Retrieve calendar payload">
                    <Show when={calendarQuery.data}>
                        {data => (
                            <PaneGrid>
                                <ScrollablePane title="iCalendar">
                                    <pre>{data().calendar}</pre>
                                </ScrollablePane>
                                <ScrollablePane title="TODOs">
                                    <JsonDebug value={parseCalendar(data().calendar)} />
                                </ScrollablePane>
                                <ScrollablePane title="First activity ocurrences">
                                    <JsonDebug
                                        // value={[
                                        //     ...parseCalendar(
                                        //         data().calendar,
                                        //     ).activityRecurrences[0].generateOccurrenceTimes(
                                        //         ICAL.Time.fromString(
                                        //             Temporal.Now.plainDateTimeISO()
                                        //                 .subtract({ days: 10 })
                                        //                 .toString(),
                                        //         ),
                                        //         ICAL.Time.fromString(
                                        //             Temporal.Now.plainDateTimeISO()
                                        //                 .add({ days: 10 })
                                        //                 .toString(),
                                        //         ),
                                        //     ),
                                        // ]}
                                        value={getActivitiesBetween(
                                            parseCalendar(data().calendar),
                                            Temporal.Now.plainDateISO().subtract({ days: 10 }),
                                            Temporal.Now.plainDateISO().add({ days: 10 }),
                                        )}
                                    />
                                </ScrollablePane>
                            </PaneGrid>
                        )}
                    </Show>
                </Tab>
            </Tabs>
        </PageWrapper>
    );
}

interface Calendar {
    activityRecurrences: ActivityRecurrence[];
}

function parseCalendar(calendar: string): Calendar {
    const vCalendar = decomposeCalendar(new ICAL.Component(ICAL.parse(calendar)));
    if (vCalendar.todos.some(todo => !todo.rrule || todo.recurrenceId)) {
        throw new Error(
            "Only todos with rrule and no recurrenceId are supported by the frontend right now",
        );
    }
    return {
        activityRecurrences: (vCalendar.todos as VTodoRecurrence[]).map(
            todoRecurrence => new ActivityRecurrence(todoRecurrence),
        ),
    };
}

function getActivitiesBetween(
    calendar: Calendar,
    startDate: Temporal.PlainDate,
    endDate: Temporal.PlainDate,
): Activity[] {
    return calendar.activityRecurrences
        .flatMap(ar => [...ar.generateOccurrences(startDate, endDate)])
        .sort(Activity.compare);
}

export class ActivityRecurrence {
    constructor(readonly todoRecurrence: VTodoRecurrence) {}

    *generateOccurrences(
        startDate: Temporal.PlainDate,
        endDate: Temporal.PlainDate,
    ): Generator<Activity> {
        // toICALTime(date) = date @ 00:00 [current timezone]
        const toICALTime = (date: Temporal.PlainDate): ICAL.Time => {
            const zdt = date.toZonedDateTime({ timeZone: Temporal.Now.timeZoneId() });
            return ICAL.Time.fromString(zdt.toString());
        };
        const rangeStart = toICALTime(startDate); // start date @ 00:00
        const rangeEnd = toICALTime(endDate.add({ days: 1 })); // next day @ 00:00 so endDate is included
        for (const time of this.generateOccurrenceTimes(rangeStart, rangeEnd)) {
            yield Activity.fromVTodo(this.todoRecurrence, time);
        }
    }

    // Here range is inclusive-exclusive, as ICAL.Time includes time and timezone
    *generateOccurrenceTimes(
        rangeStart: ICAL.Time,
        rangeEnd: ICAL.Time,
    ): Generator<Temporal.ZonedDateTime> {
        // https://github.com/kewisch/ical.js/wiki/Common-Use-Cases/5a502d4b1fd5343b6d2063c6139f502758349e8b
        // https://kewisch.github.io/ical.js/api/ICAL.RecurExpansion.html
        // https://kewisch.github.io/ical.js/api/ICAL.Recur.html#iterator

        const iter = new ICAL.RecurExpansion({
            component: composeTodo(this.todoRecurrence),
            dtstart: this.todoRecurrence.dtstart,
        });

        for (let next = iter.next(); next && next.compare(rangeEnd) < 0; next = iter.next()) {
            if (next.compare(rangeStart) < 0) continue;
            yield Temporal.ZonedDateTime.from({
                // for some reason ...next doesn't work
                year: next.year,
                month: next.month,
                day: next.day,
                hour: next.hour,
                minute: next.minute,
                second: next.second,
                // add the missing timezone!
                timeZone: this.todoRecurrence.dtstart.timezone,
            });
        }
    }
}

function Code(props: ParentProps) {
    return <code class="rounded-2 bg-black/10 px-2">{props.children}</code>;
}

function PaneGrid(props: ParentProps) {
    return <div class="grid h-[75vh] grid-cols-3 gap-10">{props.children}</div>;
}

function ScrollablePane(props: ParentProps<{ title: JSX.Element }>) {
    return (
        <ErrorBoundary fallback={error => <ErrorBlock error={error} />}>
            <div class="relative overflow-y-auto">
                <h2 class="sticky top-0 mb-3 bg-white/80 text-display-xs">{props.title}</h2>
                <div>{props.children}</div>
            </div>
        </ErrorBoundary>
    );
}

// https://github.com/aimmanager/workflow-backend?tab=readme-ov-file#calendario
interface VCalendar {
    version: string;
    prodid: string;
    todos: VTodo[];
}

export interface VTodo {
    uid: string;
    dtstamp: ICAL.Time;
    summary: string;
    categories: string[];
    status: "NEEDS-ACTION" | "COMPLETED";
    dtstart: ICAL.Time;
    rrule?: ICAL.Recur;
    duration: ICAL.Duration;
    recurrenceId?: string;
}

export interface VTodoRecurrence extends Omit<VTodo, "recurrenceId"> {
    rrule: ICAL.Recur;
}

export function decomposeCalendar(component: ICAL.Component): VCalendar {
    return {
        version: component.getFirstPropertyValue("version"),
        prodid: component.getFirstPropertyValue("prodid"),
        todos: component.getAllSubcomponents("vtodo").map(decomposeTodo),
    };
}

function composeCalendar(vCalendar: VCalendar): ICAL.Component {
    const calendarComponent = new ICAL.Component("vcalendar");
    calendarComponent.addPropertyWithValue("version", vCalendar.version);
    calendarComponent.addPropertyWithValue("prodid", vCalendar.prodid);
    vCalendar.todos.forEach(vTodo => {
        calendarComponent.addSubcomponent(composeTodo(vTodo));
    });
    return calendarComponent;
}

function decomposeTodo(component: ICAL.Component): VTodo {
    return Object.fromEntries(
        component.getAllProperties().map(p => [p.name, parseValue(p)]),
    ) as VTodo;
}

function composeTodo(vTodo: VTodo): ICAL.Component {
    const component = new ICAL.Component("vtodo");
    for (const [key, value] of Object.entries(vTodo)) {
        const property = new ICAL.Property(key);
        if (value instanceof ICAL.Time) {
            property.setParameter("tzid", value.timezone);
            property.setValue(value);
        } else if (_.isArray(value)) {
            property.setValues(value);
        } else {
            property.setValue(value);
        }
        component.addProperty(property);
    }
    return component;
}

function parseValue(property: ICAL.Property) {
    switch (property.type) {
        // case "date-time": {
        //     console.debug(property.getFirstValue());
        //     try {
        //         const metadata = property.toJSON()[1];
        //         if (metadata.tzid) {
        //             return Temporal.ZonedDateTime.from({
        //                 // eslint-disable-next-line
        //                 ...property.getFirstValue<any>(),
        //                 timeZone: metadata.tzid.replace(/^\//, ""),
        //             });
        //         } else {
        //             return Temporal.PlainDateTime.from(property.getFirstValue());
        //         }
        //     } catch (error) {
        //         console.debug(error);
        //         if (error instanceof TypeError) return property.getFirstValue();
        //         else throw error;
        //     }
        // }
        default: {
            const values = property.getValues();
            return values.length === 1 ? values[0] : values;
        }
    }
}

function FullCalendarView(props: { calendar: Calendar }) {
    const ref = createRef<HTMLDivElement>();
    const navigate = useNavigate();
    onMount(() => {
        // noinspection JSUnusedGlobalSymbols - eventClick
        const calendar = new FCCalendar(ref.current!, {
            plugins: [rrulePlugin, dayGridPlugin],
            initialView: "dayGridMonth",
            events: props.calendar.activityRecurrences
                .map(ar => ar.todoRecurrence)
                .map(tRec => ({
                    id: tRec.uid,
                    title: tRec.summary,
                    rrule: tRec.rrule.toJSON(),
                })),
            eventClick: info => {
                console.debug("Clicked calendar event", info);
                navigate(`/activities/${info.event.id}`);
            },
        });
        calendar.render();
    });
    return <div ref={ref} />;
}
