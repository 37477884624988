import { createApiQuery } from "../../utils";

export function createPeopleQuery() {
    return createApiQuery(api => ({
        queryKey: ["people"],
        queryFn: () => api.person.getPeople(),
    }));
}
export function createPersonQuery(id: string) {
    return createApiQuery(api => ({
        queryKey: ["person", id],
        queryFn: () => api.person.getPerson(id),
    }));
}

export function createPersonalDataQuery() {
    return createApiQuery(api => ({
        queryKey: ["personalData"],
        queryFn: () => api.person.retrievePersonalData(),
    }));
}
