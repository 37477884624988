import Toastify from "toastify-js";

export function showSuccessToast(message: string): void {
    Toastify({ text: message, close: true }).showToast();
}

export function showErrorToast(error: unknown): void {
    Toastify({
        text: error?.toString() ?? "Error",
        style: { background: "#FF476F" },
        duration: 10_000,
        close: true,
    }).showToast();
}
