import { OrganizationService, sOrganizationListItem } from "../interface";
import { mockOrganizations, MockOrganizationService } from "./mock";
import { makeServiceQuery } from "../../../utils";
import { publicAuthClient } from "../../../clients/paulsen";

export class V3OrganizationService implements OrganizationService {
    private mock = new MockOrganizationService();

    getCurrentOrganization = this.mock.getCurrentOrganization;

    getOrganizationBySubdomain = makeServiceQuery({
        fetchJson: async (subdomain: string) =>
            publicAuthClient.get(`/organizations/${subdomain}/`).receiveJson(),
        responseSchema: sOrganizationListItem(),
        deserialize: org => {
            //Used to mock the signInMethods
            const mockOrg = mockOrganizations.find(o => o.subdomain === org.subdomain);
            return {
                id: String(org.id),
                name: org.name,
                subdomain: org.subdomain,
                logoUrl: "",
                firebaseTenantId: org.firebase_tenant_id || null,
                signInMethods: mockOrg?.signInMethods ?? [],
            };
        },
    });
}
