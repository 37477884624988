import { JSX, ParentProps } from "solid-js";
import { OnClickDiv } from "../../utils/solidjs";

export function LavandaCard(props: ParentProps & { class?: string; onClick?: OnClickDiv }) {
    return (
        <div
            onClick={e => props.onClick?.(e)}
            class={`rounded-md bg-dark-gray-25 px-4 py-4 sm:px-12 ${props.class ?? ""}`}
        >
            {props.children}
        </div>
    );
}

export function BorderedCard(
    props: ParentProps & { class?: string; onClick?: () => void; style?: JSX.CSSProperties },
) {
    return (
        <div
            onClick={() => props.onClick?.()}
            class={`rounded-md border border-light-gray-300 p-4 ${props.class ?? ""} ${
                props.onClick ? "cursor-pointer" : ""
            }`}
            style={props.style}
        >
            {props.children}
        </div>
    );
}
