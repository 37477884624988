import { isAuthenticated } from "../../modules/auth/authContext";
import { Client, assignHeaders } from "../../modules/client/client";
import { parsedEnv } from "../../utils/parsedEnv";
import { firebaseAuthService } from "../services/auth/implementations/firebase";

export const assetsClient = new Client(`${parsedEnv.VITE_SERVER_ASSET}`, {
    intercept: async (request, fetchData) => {
        if (isAuthenticated())
            assignHeaders(request.headers, {
                Authorization: `Bearer ${await firebaseAuthService.getIdToken()}`,
            });
        return fetchData(request);
    },
});
