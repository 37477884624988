import { useLocale } from "../i18n/context";
import IntlTelField from "../forms/fields/IntlTelField";
import { useFormState } from "../forms/state";
import { createResource, Index, Match, onMount, ParentProps, Show, Switch } from "solid-js";
import { Button, GargamelButtonProps } from "../ui/components";
import {
    EditablePersonalData,
    IdentityDocument,
    IdentityType,
} from "../../api/services/person/interface";
import { normalizeRut } from "./utils";
import { getApiInstance } from "../../api";
import EmailField from "../forms/fields/EmailField";
import FileField from "../forms/fields/FileField";
import RutField from "../forms/fields/RutField";
import SelectField from "../forms/fields/SelectField";
import TextField from "../forms/fields/TextField";
import _ from "lodash";

export type PersonalDataFormValues = {
    name: string;
    identityDocuments: IdentityDocument[];
    phone?: string;
};

export function makeProfileUpdate(formValues: PersonalDataFormValues): EditablePersonalData {
    return {
        name: formValues.name,
        identityDocuments: formValues.identityDocuments.map(doc => ({
            type: doc.type,
            number: normalizeRut(doc.number),
        })),
        phones: formValues.phone ? [formValues.phone] : [],
    };
}

export function PersonalDataFields(props: { readonlyEmail: boolean }) {
    const [locale] = useLocale();
    const t = () => locale().personalData;
    const form = useFormState<PersonalDataFormValues>();
    const blankIdentityDocument: IdentityDocument = { type: "CL_CI", number: "" };
    onMount(() => {
        if (_.isEmpty(form.values.identityDocuments)) {
            form.setValues(values => {
                values.identityDocuments = [blankIdentityDocument];
            });
        }
    });
    const addIdentityDocument = () => {
        form.setValues(person => {
            person.identityDocuments.push(blankIdentityDocument);
        });
    };

    return (
        <section class="flex flex-col gap-8">
            <TextField name="name" label={t().name} />
            <EmailField name="email" label={t().email} disabled={props.readonlyEmail} />
            <div class="flex flex-col gap-4">
                <Index each={form.values.identityDocuments}>
                    {(_, index) => <IdentityDocumentFieldset index={index} />}
                </Index>
                <AddButton onClick={addIdentityDocument}>
                    {t().addAnotherIdentityDocument}
                </AddButton>
            </div>
            <PhoneFieldset />
            <FileField name="photo" label={t().photo} optional />
        </section>
    );
}

function IdentityDocumentFieldset(props: { defaultValue?: IdentityDocument; index: number }) {
    const form = useFormState<{ identityDocuments: IdentityDocument[] }>();
    const labelSuffix = () => ` #${props.index + 1}`;

    return (
        <div class="flex flex-col gap-3 lg:flex-row lg:gap-8">
            <IdentityDocumentTypeField
                name={`identityDocuments[${props.index}].type`}
                defaultValue={props.defaultValue?.type}
                labelSuffix={labelSuffix()}
            />
            <IdentityDocumentNumberField
                name={`identityDocuments[${props.index}].number`}
                documentType={form.values.identityDocuments?.[0]?.type}
                labelSuffix={labelSuffix()}
            />
        </div>
    );
}

function IdentityDocumentTypeField(props: {
    name: string;
    defaultValue?: IdentityType;
    labelSuffix: string;
}) {
    const [locale] = useLocale();
    const t = () => locale().personalData;
    const api = getApiInstance();
    const [identityOptions] = createResource(api.person.retrieveIdentityOptions);

    function makeSelectOptions(
        allowedIdentityTypes: IdentityType[],
    ): [value: string, label: string][] {
        return allowedIdentityTypes.map(type => [type, t().identityDocumentTypes[type]]);
    }

    return (
        <Show when={identityOptions()}>
            {identityOptions => (
                <SelectField
                    name={props.name}
                    label={t().identityDocumentType + props.labelSuffix}
                    options={makeSelectOptions(identityOptions().allowedIdentityTypes)}
                    defaultValue={props.defaultValue ?? identityOptions().defaultIdentityType}
                />
            )}
        </Show>
    );
}

function IdentityDocumentNumberField(props: {
    name: string;
    documentType: string;
    labelSuffix: string;
}) {
    const [locale] = useLocale();
    const t = () => locale().personalData;
    const label = () => t().identityDocumentNumber + props.labelSuffix;

    return (
        <Show when={props.documentType}>
            {identityType => (
                <Switch
                    fallback={
                        <TextField
                            name={props.name}
                            label={label()}
                            placeholder="Validación no disponible esta demo"
                        />
                    }
                >
                    <Match when={identityType() === "CL_CI"}>
                        <RutField name={props.name} label={label()} />
                    </Match>
                </Switch>
            )}
        </Show>
    );
}

function PhoneFieldset(props: { defaultValue?: string[] }) {
    const [locale] = useLocale();
    const t = () => locale().personalData;

    return (
        <fieldset class="flex flex-col gap-2">
            <IntlTelField
                name="phone"
                label={t().phone}
                optional
                defaultValue={props.defaultValue?.[0]}
            />
            <AddButton>{t().addAnotherPhone}</AddButton>
        </fieldset>
    );
}

function AddButton(props: ParentProps<{ onClick?: GargamelButtonProps["onClick"] }>) {
    const [locale] = useLocale();

    return (
        <Button
            type="button"
            bgStyle="text-only"
            size="sm"
            icon="fas fa-plus"
            style={{ "align-self": "flex-end" }}
            onClick={props.onClick ?? (() => alert(locale().utils.notAvailableForDemo))}
        >
            {props.children}
        </Button>
    );
}
