import { Organization, OrganizationService } from "../interface";
import myCompanyLogo from "../../../../assets/imgs/logo-mycompany-default.png";
import { SignInMethod } from "../../auth/interface";
import { sleep } from "../../../../utils/mocks";
import { makeServiceQuery } from "../../../utils";
import { array, enums, nullable, string, type } from "superstruct";
import { getUserOr401 } from "../../../../modules/auth/authContext";

export class MockOrganizationService implements OrganizationService {
    getCurrentOrganization = async (): Promise<Organization> => {
        const user = getUserOr401();
        const organization = mockOrganizations.find(
            org => org.firebaseTenantId === user.firebaseTenantId,
        );
        if (!organization)
            throw new Error(
                `Mock organization with firebaseTenantId ${user.firebaseTenantId} not found`,
            );
        return organization;
    };

    getOrganizationBySubdomain = makeServiceQuery({
        fetchJson: async (subdomain: string): Promise<Organization | null> => {
            await sleep(1000);
            return mockOrganizations.find(org => org.subdomain === subdomain) ?? null;
        },
        responseSchema: nullable(sMockOrganization()),
        deserialize: (json: Organization | null): Organization | null => json,
    });
}

function sMockOrganization() {
    return type({
        id: string(),
        name: string(),
        subdomain: string(),
        logoUrl: string(),
        firebaseTenantId: nullable(string()),
        signInMethods: array(enums(Object.values(SignInMethod))),
    });
}

export const mockOrganizations: Organization[] = [
    {
        id: "myc0mp4ny",
        name: "MyCompany",
        subdomain: "mycompany",
        logoUrl: myCompanyLogo,
        firebaseTenantId: "mycompany-5nsfv",
        signInMethods: [SignInMethod.GOOGLE, SignInMethod.EMAIL_PASSWORD, SignInMethod.EMAIL_LINK],
    },
    {
        id: "d4nh05",
        name: "Danhos",
        subdomain: "danhos",
        logoUrl: "https://fibradanhos.com.mx/img/logo.png",
        firebaseTenantId: null,
        signInMethods: [SignInMethod.EMAIL_PASSWORD],
    },
];
