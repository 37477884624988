import { DefaultError, QueryKey } from "@tanstack/query-core";
import { Api, getApiInstance } from "./index";
import {
    createMutation,
    createQuery,
    QueryCache,
    QueryClient,
    SolidMutationOptions,
    SolidQueryOptions,
} from "@tanstack/solid-query";
import { array, create, Describe, integer, refine, string, Struct, type } from "superstruct";
import { showErrorToast } from "../utils/errorHandling";

export const queryClient = new QueryClient({
    queryCache: new QueryCache({
        onError: error => {
            console.error(error);
            showErrorToast(error);
        },
    }),
    defaultOptions: {
        queries: {
            // Hack for demo (see https://github.com/TanStack/query/issues/2960)
            refetchOnWindowFocus: false,
        },
    },
});

/** Hook with boilerplate to create a query that uses the API Facade.
 * It has a similar signature to `createQuery` from `@tanstack/solid-query`. */
export function createApiQuery<
    TQueryFnData = unknown,
    TError = DefaultError,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey,
>(
    options: (
        api: Api,
    ) => SolidQueryOptions<TQueryFnData, TError, TData, TQueryKey> & { initialData?: undefined },
) {
    const api = getApiInstance();
    return createQuery(() => options(api));
}

/** Hook with boilerplate to create a mutation that uses the API Facade.
 * It has a similar signature to `createMutation` from `@tanstack/solid-query`.
 *
 * @remarks - You can hold the Alt/Option key during development to simulate an
 * error response.
 */
export function createApiMutation<
    TData = unknown,
    TError = DefaultError,
    TVariables = void,
    TContext = unknown,
>(options: (api: Api) => SolidMutationOptions<TData, TError, TVariables, TContext>) {
    return createMutation(() => {
        const api = getApiInstance();
        const o = options(api);
        return o;
    });
}

/** A SuperStruct struct that validates that a string is a valid datetime.
 * This keeps the value as a string, without converting it to a Date, so
 * it can be used in contexts where you want the value to be serializable
 * as JSON, like persisting endpoint responses on the device storage.
 */
export function sDateTimeString() {
    return refine(string(), "datetime string", str => !Number.isNaN(new Date(str).getTime()));
}

/** A standard interface for the result of paginated endpoints.
 *
 * The backend services may actually return paginated endpoints in a different
 * format, but our API Facade will convert all of them to this format.
 *
 * That way we can implement pagination logic on the frontend once.
 *
 * This is inspired by [Django Rest Framework pagination](https://www.django-rest-framework.org/api-guide/pagination/)
 * as most of V3 is made on Django Rest Framework.
 */
export type Paginated<T> = {
    // type instead of interface so it satisfies Json
    /** How many items there are in total across all pages. */
    count: number;
    /** Items in the current page. */
    results: T[];
};

export function sPaginated<T, S>(t: Struct<T, S>) {
    return type({
        count: integer(),
        results: array(t),
    });
}

/** Standard parameters for paginated endpoints, so we can implement pagination
 * logic on the frontend once. The backend may receive the params in a different
 * format, but our API Facade will make that conversion.
 */
export type PaginationParams = {
    /** From 1 to N. */
    pageNumber: number;
    /** If not provided, the backend chooses. */
    pageSize?: number;
};

/** @see makeServiceQuery */
export interface ServiceQuery<TParams extends unknown[], TResponseObj extends NonUndefined> {
    /** Fetcher function to be called by queryFn. */
    fetchJson: (...params: TParams) => Promise<Json>;
    /** The `select` function that you should pass to the query options. */
    select: (json: Json) => TResponseObj;
    /** This can be used to fetch the endpoint and run the deserializer at the same time. */
    (...params: TParams): Promise<TResponseObj>;
}

/** Creates an object specifying a read operation of a service, i.e. a GET
 * endpoint, that can be used to create a query with Tanstack Query.
 *
 * What would be a simple async function is split into a couple of steps,
 * so it works correctly with a Tanstack Query persister.
 *
 * A separate `deserialize` function is required because
 * the persister is not able to deserialize non-JSON values.
 *
 * Also, a `responseSchema` is required for two reasons:
 * - Validate that the backend response is correct.
 * - Validate that the persisted query data is not corrupted.
 */
export function makeServiceQuery<
    TParams extends unknown[],
    TResponseJson extends Json,
    TResponseObj extends NonUndefined,
>(options: {
    /** Gets the raw JSON response from the backend, without validating
     * nor modifying the JSON. The backend may be actually a mock. */
    fetchJson: (...params: TParams) => Promise<Json>;
    /** A SuperStruct struct to validate the response JSON against. */
    // If Describe creates problems, Struct<TResponseJson, any> may be the solution.
    responseSchema: Describe<TResponseJson> | Struct<TResponseJson>;
    /** Converts the JSON response from the backend to an arbitrary JS object. */
    deserialize: (json: TResponseJson) => TResponseObj;
}): ServiceQuery<TParams, TResponseObj> {
    const sq = async function (...params: TParams): Promise<TResponseObj> {
        return options.deserialize(
            create(await options.fetchJson(...params), options.responseSchema),
        );
    };
    sq.fetchJson = async (...params: TParams) =>
        // Validate against schema here, as select seems to shallow errors
        create(await options.fetchJson(...params), options.responseSchema);
    sq.select = (json: Json) =>
        /* Validate against the schema here as well as the json may come from
         * a corrupted persisted query data. The select function will still
         * throw, but throwing StructError in this case allows us to handle
         * this in a generic way in the future. */
        options.deserialize(create(json, options.responseSchema));
    return sq;
}

type NonUndefined = Exclude<unknown, undefined>;

/** @remarks - It doesn't accept objects defined using `interface`,
 * it has to be `type`. */
export type Json = { [key: string]: Json } | Json[] | string | number | boolean | null;

/** Same semantics as a 401, you can throw this at mock or virtual endpoints.  */
export class UnauthorizedError extends Error {
    constructor() {
        super("Unauthorized call to protected endpoint, the user is not signed in");
        console.debug(this.message);
        Object.setPrototypeOf(this, UnauthorizedError.prototype);
        Error.captureStackTrace?.(this, UnauthorizedError);
        this.name = this.constructor.name;
    }
}
