import { PageWrapper } from "../ui/pageWrappers";
import { createPeopleQuery } from "../../api/services/person/queries";
import { DevSuspense } from "../ui/skeletons";
import { Show } from "solid-js";
import { PeopleHeader, PeopleSkeleton, PeopleList } from "./PeopleList";
import Warning from "../ui/Warning";

export default function PeoplePage() {
    const peopleQuery = createPeopleQuery();

    return (
        <PageWrapper>
            <Warning>
                Esto es una maqueta, este módulo aún no se ha comenzado a desarrollar.
            </Warning>
            <div>
                <PeopleHeader />
            </div>
            <DevSuspense fallback={<PeopleSkeleton />}>
                <Show when={peopleQuery.data}>{people => <PeopleList persons={people()} />}</Show>
            </DevSuspense>
        </PageWrapper>
    );
}
