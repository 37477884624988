import { array, Infer, integer, string, type } from "superstruct";
import { deviceClient } from "../../../../modules/client/client";
import {
    AuthService,
    BackendVersion,
    ReceiveMagicCodeRequest,
    ReceiveMagicCodeResponse,
    WorkspaceOverview,
} from "../interface";
import MockAuthService from "./mock";
import { Organization } from "../../organization/interface";
import { firebaseAuthService } from "./firebase";

export default class AtlasAuthService implements AuthService {
    // For endpoints not implemented on backend yet
    private mock = new MockAuthService();

    listWorkspaces = async (email: string): Promise<WorkspaceOverview[]> => {
        const byCompanyName = (a: WorkspaceOverview, b: WorkspaceOverview) =>
            a.companyName.localeCompare(b.companyName);

        return await deviceClient
            .get(`/users/${email}/accounts`)
            .receive(array(sAtlasAccount()))
            .then(accounts => accounts.map(accountToWorkspace))
            .then(workspaces => workspaces.sort(byCompanyName)); // [1]

        /* [1] Backend returns the accounts in a different order each time. */
    };

    signInWithEmailAndPassword = async (
        organization: Organization,
        email: string,
        password: string,
    ): Promise<void> => {
        await firebaseAuthService.signInWithEmailAndPassword(organization, email, password);
    };

    signInWithGoogle = async (
        organization: Organization,
        email: string | undefined,
    ): Promise<void> => {
        await firebaseAuthService.signInWithGoogle(organization, email);
    };

    sendPasswordResetEmail = async (organization: Organization, email: string): Promise<void> => {
        await firebaseAuthService.sendPasswordResetEmail(organization, email);
    };

    getBearerToken = async (): Promise<string> => {
        return await firebaseAuthService.getIdToken();
    };

    signOut = async (): Promise<void> => {
        await firebaseAuthService.signOut();
    };

    sendMagicCode = async (email: string): Promise<void> => {
        return await this.mock.sendMagicCode(email);
    };

    receiveMagicCode = async (
        request: ReceiveMagicCodeRequest,
    ): Promise<ReceiveMagicCodeResponse | null> => {
        return await this.mock.receiveMagicCode(request);
    };

    sendSigninLinkToEmail = firebaseAuthService.sendSigninLinkToEmail;
    sendInvite = firebaseAuthService.sendInvite;

    isSignInWithEmailLink = async (link: string) => {
        return await firebaseAuthService.isSignInWithEmailLink(link);
    };

    signInWithEmailLink = firebaseAuthService.signInWithEmailLink;
}

type AtlasAccount = Infer<ReturnType<typeof sAtlasAccount>>;
function sAtlasAccount() {
    return type({
        id: integer(),
        logo: string(),
        name: string(),
        subdomain: string(),
    });
}

function accountToWorkspace(account: AtlasAccount): WorkspaceOverview {
    return {
        companyLogoUrl: account.logo,
        companyName: account.name,
        subdomain: account.subdomain,
        backendVersion: BackendVersion.Atlas,
    };
}
