import {
    Activity,
    ActivityTask,
    ActivityType,
    ExampleActivity,
    Priority,
    TaskManagerService,
    WorkflowActivity,
} from "../interface";
import { makeServiceQuery, Paginated, PaginationParams, sPaginated } from "../../../utils";
import { sleep } from "../../../../utils/mocks";
import { range } from "../../../../utils/miniLodash";
import { array, enums, Infer, intersection, literal, string, type, union } from "superstruct";
import { datetime, RRule } from "rrule";
import { WorkflowExecution } from "../../workflow/interface";
import { sV3ActivityTask } from "./v3";

export default class MockTaskManagerService implements TaskManagerService {
    private recurrences = mockRecurrences(Temporal.Now.plainDateISO(), Temporal.Now.instant());
    private activityTasks: ActivityTask[] = [];
    // {
    //     id: "1",
    //     activity: "1",
    //     content_type_model: "1",
    //     object_id: "2"
    // }
    paginateMyActivities = makeServiceQuery({
        fetchJson: async (params: PaginationParams) => {
            await sleep(200);
            const pageSize = params.pageSize ?? 20;
            const startIndex = (params.pageNumber - 1) * pageSize;
            const today = Temporal.Now.zonedDateTimeISO();
            return {
                count: 9999,
                results: range(startIndex, startIndex + pageSize).map(index =>
                    mockMyActivity(index, today),
                ),
            };
        },
        responseSchema: sPaginated(sMockActivityJson()),
        deserialize: (jsonPage: Paginated<MockActivityJson>): Paginated<Activity> => ({
            count: jsonPage.count,
            results: jsonPage.results.map(deserializeActivity),
        }),
    });

    retrieveMyCalendar = makeServiceQuery({
        fetchJson: async () => this.recurrences,
        responseSchema: array(sMockRecurrence()),
        deserialize: recurrences => ({
            getActivities(startDate: Temporal.PlainDate, endDate: Temporal.PlainDate): Activity[] {
                return recurrences
                    .flatMap(({ rruleStr, ...recurrence }) =>
                        RRule.fromString(rruleStr)
                            .between(toRRuleDateTime(startDate), toRRuleDateTime(endDate), true)
                            .map((jsDate, index) => {
                                const id = `${recurrence.id}-${index + 1}`;
                                const dueDate = jsDateToZdt(jsDate);
                                switch (recurrence.type) {
                                    case ActivityType.Example:
                                        return new ExampleActivity({ ...recurrence, id, dueDate });
                                    case ActivityType.Workflow:
                                        return new WorkflowActivity({ ...recurrence, id, dueDate });
                                }
                            }),
                    )
                    .sort(Activity.compare);
            },
        }),
    });

    handleNewWorkflowExecution = (execution: WorkflowExecution) => {
        this.recurrences.push({
            id: `w0rkf10w-${execution.id}`,
            title: "Solicitud de vacaciones",
            group: { name: "Parque Workflow" },
            priority: Priority.ThreeUpArrows,
            rruleStr: new RRule({
                freq: RRule.DAILY,
                dtstart: toRRuleDateTime(Temporal.Instant.from(execution.createdAt)),
                count: 1,
            }).toString(),
            type: ActivityType.Workflow,
            executionId: execution.id,
        });
    };
    async createActivity(activity: unknown) {
        console.log("Creating activity", activity);
        throw new Error("Not implemented");
    }
    createActivityTask(activityId: string): void {
        console.log("create activity task", activityId);
        throw new Error("Not implemented");
    }

    activateActivity(activityId: string): void {
        console.log("activate activity", activityId);
        throw new Error("Not implemented");
    }

    getActivityTasks = makeServiceQuery({
        fetchJson: async () => this.activityTasks,
        responseSchema: array(sMockActivityTask()),
        deserialize: tasks => tasks,
    });

    recurrenceActivities(id: string): void {
        console.log("recurrence activities", id);
        throw new Error("Not implemented");
    }

    startEvent(): void {
        console.log("start event");
        throw new Error("Not implemented");
    }

    messageStartEvent(): void {
        console.log("message start event");
        throw new Error("Not implemented");
    }

    checklistExecutionStartEvent(): void {
        console.log("checklist execution start event");
        throw new Error("Not implemented");
    }

    formResponseStartEvent(): void {
        console.log("form response start event");
        throw new Error("Not implemented");
    }
}

type MockActivityJson = Infer<ReturnType<typeof sMockActivityJson>>;

function sMockActivityJson() {
    return union([
        intersection([
            sBaseMockActivity(),
            type({
                type: literal(ActivityType.Example),
            }),
        ]),
        intersection([
            sBaseMockActivity(),
            type({
                type: literal(ActivityType.Workflow),
                executionId: string(),
            }),
        ]),
    ]);
}

function sBaseMockActivity() {
    return type({
        id: string(),
        title: string(),
        group: type({ name: string() }),
        priority: enums([
            Priority.Reminder,
            Priority.ThreeUpArrows,
            Priority.TwoUpArrows,
            Priority.OneUpArrow,
            Priority.Standard,
        ]),
        dueDate: string(),
    });
}

function deserializeActivity(payload: MockActivityJson): Activity {
    return new ExampleActivity({
        ...payload,
        dueDate: Temporal.ZonedDateTime.from(payload.dueDate),
    });
}

function mockMyActivity(index: number, today: Temporal.ZonedDateTime): MockActivityJson {
    switch (index) {
        case 0:
            return {
                id: "c1t4-m3d1c4",
                title: "Cita médica 1 solo vez",
                group: { name: "Parque Alameda" },
                priority: Priority.Reminder,
                dueDate: today.toString(),
                type: ActivityType.Example,
            };
        case 1:
            return {
                id: "r3c0r-d4t0r10",
                title: "Recordatorio inicio vigencia audiencia se repite",
                group: { name: "Parque Alameda" },
                priority: Priority.Reminder,
                dueDate: today.toString(),
                type: ActivityType.Example,
            };
        default:
            return {
                id: `s4c4r-l4-b4sur4-${index}`,
                title: "Sacar la basura",
                group: { name: index === 2 ? "Parque Alameda" : "Parque Alameda - Boulevard" },
                priority: index === 2 ? Priority.ThreeUpArrows : Priority.TwoUpArrows,
                dueDate: today.add({ days: index - 1 }).toString(),
                type: ActivityType.Example,
            };
    }
}

type Recurrence = {
    id: string;
    title: string;
    group: { name: string };
    priority: Priority;
    rruleStr: string;
} & (
    | {
          type: ActivityType.Example;
      }
    | {
          type: ActivityType.Workflow;
          executionId: string;
      }
);
const sMockActivityTask = sV3ActivityTask;
function sMockRecurrence() {
    return union([
        intersection([sBaseMockRecurrence(), type({ type: literal(ActivityType.Example) })]),
        intersection([
            sBaseMockRecurrence(),
            type({
                type: literal(ActivityType.Workflow),
                executionId: string(),
            }),
        ]),
    ]);
}

function sBaseMockRecurrence() {
    return type({
        id: string(),
        title: string(),
        group: type({ name: string() }),
        priority: enums(Object.values(Priority) as Priority[]),
        rruleStr: string(),
    });
}

function mockRecurrences(today: Temporal.PlainDate, now: Temporal.Instant): Recurrence[] {
    return [
        {
            id: "c1t4-m3d1c4",
            title: "Cita médica 1 solo vez",
            group: { name: "Parque Alameda" },
            priority: Priority.Reminder,
            rruleStr: new RRule({
                freq: RRule.DAILY,
                dtstart: toRRuleDateTime(today),
                count: 1,
            }).toString(),
            type: ActivityType.Example,
        },
        {
            id: "r3c0r-d4t0r10",
            title: "Recordatorio inicio vigencia audiencia se repite",
            group: { name: "Parque Alameda" },
            priority: Priority.Reminder,
            rruleStr: new RRule({
                freq: RRule.DAILY,
                dtstart: toRRuleDateTime(today),
                count: 2,
            }).toString(),
            type: ActivityType.Example,
        },
        {
            id: `s4c4r-l4-b4sur4-ur63n73`,
            title: "Sacar la basura",
            group: { name: "Parque Alameda" },
            priority: Priority.ThreeUpArrows,
            rruleStr: new RRule({
                dtstart: toRRuleDateTime(now.add({ hours: 3 })),
                count: 1,
            }).toString(),
            type: ActivityType.Example,
        },
        {
            id: `s4c4r-l4-b4sur4`,
            title: "Sacar la basura",
            group: { name: "Parque Alameda - Boulevard" },
            priority: Priority.TwoUpArrows,
            rruleStr: new RRule({
                freq: RRule.DAILY,
                dtstart: toRRuleDateTime(today.add({ days: 3 })),
            }).toString(),
            type: ActivityType.Example,
        },
    ];
}

function toRRuleDateTime(date: Temporal.PlainDate | Temporal.Instant) {
    const d =
        date instanceof Temporal.Instant
            ? date.toZonedDateTimeISO(Temporal.Now.timeZoneId())
            : date;

    return d instanceof Temporal.PlainDate
        ? datetime(d.year, d.month, d.day)
        : datetime(d.year, d.month, d.day, d.hour, d.minute, d.second);
}

function jsDateToZdt(jsDate: Date): Temporal.ZonedDateTime {
    return Temporal.ZonedDateTime.from({
        year: jsDate.getUTCFullYear(),
        month: jsDate.getUTCMonth() + 1,
        day: jsDate.getUTCDate(),
        hour: jsDate.getUTCHours(),
        minute: jsDate.getUTCMinutes(),
        timeZone: Temporal.Now.timeZoneId(),
    });
}
