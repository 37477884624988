import { Badge, Button } from "../ui/components";
import { getHmsDurationBetween } from "../../utils/formatDate";
import { P } from "../../utils/typography";
import { ActivityIcon, Countdown } from "./ActivityItemMobile";
import { Tabs } from "../ui/Tabs";
import { TaskTab } from "./detailPage/TaskTab";
import { DetailTab } from "./detailPage/DetailTab";
import { PageWrapper } from "../ui/pageWrappers";
import { Priority } from "../../api/services/task-manager/interface";
import { createMemo, Show } from "solid-js";
import { createModalController } from "../ui/Modal";
import { TaskDetailModal } from "./modals/TaskDetailModal";
import { EndTaskModal } from "./modals/EndTaskModal";
import { createRef } from "../../utils/reactRefs";
import { useResponsiveBreakpoints } from "../../utils/useResponsiveBreakpoints";
import { useClock } from "../../utils/clock";
import BackButton from "../../utils/components/BackButton";

type fakeType = {
    priority: Priority;
    date: Temporal.ZonedDateTime;
};
const fakeData: fakeType = {
    priority: Priority.ThreeUpArrows,
    date: Temporal.Now.zonedDateTimeISO().with({ hour: 23, minute: 59, second: 0 }),
};

export default function ActivitiesDetailPage() {
    const { sm } = useResponsiveBreakpoints();
    const badgeClasses: Record<Priority, string> = {
        [Priority.Reminder]:
            "border border-primary-400 bg-primary-100 font-medium text-primary-700",
        [Priority.ThreeUpArrows]: "border border-error-100 bg-error-50 font-medium text-error-700",
        [Priority.TwoUpArrows]:
            "border border-warning-200 bg-warning-50 font-medium text-warning-700",
        [Priority.OneUpArrow]: "",
        [Priority.Standard]: "",
    };
    const { nowZdt } = useClock();
    const remainingTime = createMemo(() => getHmsDurationBetween(nowZdt(), fakeData.date));
    const taskDetailModalController = createModalController<void, void>();
    const endTaskModalController = createModalController<void, void>();

    const ref = createRef<HTMLDialogElement>();

    return (
        <PageWrapper class={"bg-white !pb-0"}>
            <div class={"grid grid-cols-12 gap-x-6"}>
                <div
                    class={
                        "relative col-span-12 overflow-y-scroll md:col-span-8 md:max-h-[calc(100vh-130px)]"
                    }
                >
                    <div class={"sticky top-0 z-dropdown bg-white pb-6"}>
                        {/*<AlertMessage
                            title={"Actividad finalizada con éxito"}
                            type={"success"}
                            class={"mb-3"}
                        />
                        <AlertMessage
                            title={"Actividad expirada"}
                            subtitle={
                                "Su fecha se cumplió y el tiempo extra expiró. No puede ser completada"
                            }
                            type={"error"}
                            class={"mb-3"}
                        />
                        <AlertMessage
                            title={"Actividad programada"}
                            subtitle={"Puede ser realizada a partir del {start_date}"}
                            type={"program_activity"}
                            class={"mb-3"}
                        />*/}

                        <BackButton url={"/activities/"} />

                        <div class={"flex justify-between"}>
                            <Badge
                                class={`${
                                    badgeClasses[fakeData.priority]
                                } flex items-center justify-center gap-x-1 !pr-3`}
                            >
                                <ActivityIcon priority={fakeData.priority} />
                                <P class={"!mb-0 text-sm"}>Crítico</P>
                            </Badge>
                            <Countdown
                                remainingTime={remainingTime()}
                                priority={fakeData.priority}
                            />
                        </div>
                        <P class={"!mb-2 text-xl font-medium"}>Sacar la basura</P>
                        <div class={"flex gap-x-1"}>
                            <P class={"!mb-0"}>Recinto:</P>
                            <Badge class={"w-fit border border-light-gray-200 !bg-light-gray-50"}>
                                Parque Alameda
                            </Badge>
                        </div>
                    </div>

                    <Tabs>
                        <TaskTab modalController={taskDetailModalController} />
                        <DetailTab />
                    </Tabs>

                    <Show when={!sm()}>
                        <div class={`fixed bottom-24 left-0 z-dropdown w-full bg-white p-2`}>
                            <Button
                                variant={"primary"}
                                size={"lg"}
                                class={"w-full"}
                                onClick={() => {
                                    ref.current?.showModal();
                                    endTaskModalController.open();
                                }}
                            >
                                Finalizar actividad
                            </Button>
                        </div>
                    </Show>
                    <Show when={sm()}>
                        <div
                            class={
                                "sticky bottom-0 flex w-full justify-end gap-x-6 bg-white pb-4 pt-6"
                            }
                        >
                            <Button
                                variant={"primary"}
                                bgStyle={"outline"}
                                size={"lg"}
                                onClick={() => {
                                    ref.current?.showModal();
                                    endTaskModalController.open();
                                }}
                            >
                                Finalizar en otro momento
                            </Button>
                            <Button
                                variant={"primary"}
                                size={"lg"}
                                onClick={() => {
                                    console.log("Finalizar actividad");
                                }}
                            >
                                Finalizar actividad
                            </Button>
                        </div>
                    </Show>
                </div>
                <div class={"hidden md:flex"} />
            </div>

            <TaskDetailModal controller={taskDetailModalController} />
            <EndTaskModal controller={endTaskModalController} />
        </PageWrapper>
    );
}
