import { FormValues } from "../../../modules/forms/state";
import { ServiceQuery } from "../../utils";
import { InputUnion } from "../formbuilder/interfaces/inputs/BaseInput";
import { DynamicForm } from "../formbuilder/DynamicForm";

export interface WorkflowService {
    listWorkflows(): Promise<Workflow[]>;
    createWorkflow(name: string): Promise<Workflow>;
    retrieveWorkflow(workflowId: string): Promise<Workflow>;
    updateWorkflowForm(params: {
        formId: string;
        fields: InputUnion[];
        processVars: Record<string, string>;
    }): Promise<void>;
    createWorkflowForm(params: {
        fields: InputUnion[];
        processVars: Record<string, string>;
        workflowId: string;
    }): Promise<{ id: string }>;
    retrieveWorkflowForm(formId: string): Promise<WorkflowForm>;
    setBpmn(params: SetBpmnParams): Promise<void>;
    getFormByBpmnElement(params: {
        workflowId: string;
        bpmnElementId: string;
    }): Promise<WorkflowForm>;

    /** Sets the workflow as active, meaning that it can now be executed,
     * but can't be edited anymore. On V3, workflows can only become inactive
     * again through Django Admin.
     */
    activateWorkflow(workflowId: string): Promise<void>;

    /** Starts a new execution of a workflow.
     *
     * A workflow may have multiple start events, so we need to specify which
     * start event will be used to start the execution.
     *
     * @remarks - The workflow must be active.
     */
    executeWorkflow(params: ExecuteWorkflowParams): Promise<WorkflowExecution>;

    listWorkflowExecutions: ServiceQuery<[], WorkflowExecution[]>;

    retrieveWorkflowExecution: ServiceQuery<[executionId: string], WorkflowExecution>;

    listWorkflowElements(executionId: string): Promise<WorkflowElement[]>;

    performWorkflowAction(params: PerformWorkflowActionParams): Promise<void>;

    retrieveFormResponse(params: {
        formId: string;
        executionId: string;
    }): Promise<FormResponse | null>;
}

export interface Workflow {
    id: string;
    isActive: boolean;
    name: string;
    bpmn: string | null;
    startEvents: StartEvent[];
    createdAt: Temporal.Instant;
    updatedAt: Temporal.Instant;
}

export type StartEvent =
    | {
          type: WorkflowElementType.VanillaStartEvent;
          bpmnElementId: string;
      }
    | {
          type: WorkflowElementType.MessageStartEvent;
          bpmnElementId: string;
          name: string;
      };

/** BPMN tasks and other workflow elements may contain a form.
 * Those forms are called workflow forms, to distinguish them
 * from e.g. checklist forms. */
export interface WorkflowForm extends DynamicForm {
    /** A mapping from `field.name` (not `field.id`) to a variable name that can
     * be used by conditions (e.g. `show_if`) or BPMN (e.g. gateways). */
    processVars: Record<string, string>;
    /** Which workflow this form belong to? */
    workflowId: string;
}

export type WorkflowExecution = {
    id: string;
    createdAt: Temporal.Instant;
    updatedAt: Temporal.Instant;
    workflowId: string;
} & WorkflowCompletion;

export type WorkflowCompletion =
    | {
          status: WorkflowCompletionStatus.InProcess;
      }
    | {
          status: WorkflowCompletionStatus.Success;
          completedAt: Temporal.Instant;
      }
    | {
          status: WorkflowCompletionStatus.Error;
          completedAt: Temporal.Instant;
          errorMessage: string;
      };

export enum WorkflowCompletionStatus {
    InProcess = "InProcess",
    Success = "Success",
    Error = "Error",
}

export interface WorkflowElement {
    id: string;
    name: string;
    bpmnElementId: string;
    type: WorkflowElementType;
    isActive: boolean;
}

export enum WorkflowElementType {
    VanillaStartEvent = "VanillaStartEvent",
    MessageStartEvent = "MessageStartEvent",
    UserTask = "UserTask",
    TimerIntermediateCatchEvent = "TimerIntermediateCatchEvent",
    MessageIntermediateCatchEvent = "MessageIntermediateCatchEvent",
    MessageIntermediateThrowEvent = "MessageIntermediateThrowEvent",
    SuccessEndEvent = "SuccessEndEvent",
    ErrorEndEvent = "ErrorEndEvent",
}

/** Previous answers that some user provided in the past to a workflow's form. */
export interface FormResponse {
    createdAt: string; // string so it can be serialized on persistent storage
    updatedAt: string; // string so it can be serialized on persistent storage
    /** An object with the previous responses, or null if nobody has answered
     * the form yet. */
    formValues: FormValues | null;
    userId: string;
}

export type ExecuteWorkflowParams = {
    workflowId: string;
    startEventElementId: string;
} & (
    | {
          startEventType: WorkflowElementType.VanillaStartEvent;
      }
    | {
          startEventType: WorkflowElementType.MessageStartEvent;
          formValues: FormValues;
      }
);

export type SetBpmnParams = {
    workflowId: string;
    bpmn: string;
};

export type PerformWorkflowActionParams = {
    executionId: string;
    bpmnElementId: string;
    formValues: FormValues;
};

/** OLD interface for Checklist question (example taken from reporte-de-mantenimiento.json)
 * 
 * {
 *      "id": 2376,
        "rank": 11.0,
        "name": "11. Puertas automáticas, cortinas eléctricas, torniquetes",
        "checklist_interface_id": 340,
        "grade_weight": 32.0,
        "description": null,
        "rich_description": "",
        checklist_step_interfaces: [
            {
            "id": 26743,
            "rank": 0.0,
            "name": "Puerta #1  Vestíbulo sótano 2 administración",
            "rich_name": "Puerta #1  Vestíbulo sótano 2 administración",
            "description": null,
            "rich_description": null,
            "low_grade": null,
            "required": true,
            "skippable": false,
            "grade_weight": 2.0,
            "light_question": true,
            "default_grade": null,
            "pictures_high_grade": "No utilizado",
            "documents_high_grade": "No utilizado",
            "comments_high_grade": "No utilizado",
            "pictures_low_grade": "No utilizado",
            "documents_low_grade": "No utilizado",
            "comments_low_grade": "No utilizado",
            "grade_vals": [],
            "has_grade": true,
            "checklist_section_interface_id": 2376,
            "parent_checklist_step_interface_id": null,
            "parent_checklist_grade_option_id": null,
            "default_checklist_grade_option_id": null,
            "incident_interface_id": null,
            "infrastructure_interface_ids": [],
            "checklist_step_tags": [],
            "checklist_grade_options": [
              {
                "id": 65648,
                "name": "Muy Buen Estado",
                "grade": 100,
                "incident_interface_id": null,
                "pictures": "Opcional",
                "documents": "No utilizado",
                "comments": "Opcional",
                "default_tag_for_incident_id": null
              },
              {
                "id": 65649,
                "name": "Pésimo Estado",
                "grade": 0,
                "incident_interface_id": 187,
                "pictures": "Obligatorio",
                "documents": "No utilizado",
                "comments": "Obligatorio",
                "default_tag_for_incident_id": null
              }
            ]
          },
        ]
 * }
 * 
 */
// "id": 340,
// "name": "Reporte de Mantenimiento (Parque Delta)",
// "icon": "https://s3.amazonaws.com/danhos-aimmanager/aim/checklists/checklist_interfaces/icons/000/000/340/original/satelite_mantenimientoyoperaciones.png?1649254606",
// "grade_min": 0,
// "grade_max": 100,
// "expiration_time": 0.0,
// "autogenerate_incident": false,
// "is_section_weight": true,
// "is_for_store": false,
// "is_for_service": false,
// "is_for_infrastructure": false,
// "is_for_product": false,
// "start_location": "No utilizado",
// "finish_location": "No utilizado",
// "add_photo_from_gallery": true,
// "checklist_category_id": 3,
// "concept_id": 5,
// "read": true,
// "create": true,
// "update": true,
// "delete": true,
export type ChecklistPayload = {
    version: string;
    data: ChecklistQuestionaire;
};
export type ChecklistQuestionaire = {
    id: number;
    name: string;
    icon: string;
    grade_min: number;
    grade_max: number;
    expiration_time: number;
    autogenerate_incident: boolean;
    is_section_weight: boolean;
    is_for_store: boolean;
    is_for_service: boolean;
    is_for_infrastructure: boolean;
    is_for_product: boolean;
    start_location: string;
    finish_location: string;
    add_photo_from_gallery: boolean;
    checklist_category_id: number;
    concept_id: number;
    read: boolean;
    create: boolean;
    update: boolean;
    delete: boolean;
    checklist_section_interfaces: ChecklistSection[];
    checklist_tags: string[];
    map_ids: number[];
};
export type ChecklistSection = {
    id: number;
    rank: number;
    name: string;
    checklist_interface_id: number;
    grade_weight: number;
    description: string | null;
    rich_description: string;
    checklist_step_interfaces: ChecklistStep[];
};

export type ChecklistStep = {
    id: number;
    rank: number;
    name: string;
    rich_name: string;
    description: string | null;
    rich_description: string | null;
    low_grade: number | null;
    required: boolean;
    skippable: boolean;
    grade_weight: number;
    light_question: boolean;
    default_grade: number | null;
    pictures_high_grade: string;
    documents_high_grade: string;
    comments_high_grade: string;
    pictures_low_grade: string;
    documents_low_grade: string;
    comments_low_grade: string;
    grade_vals: unknown[];
    has_grade: boolean;
    checklist_section_interface_id: number;
    parent_checklist_step_interface_id: number | null;
    parent_checklist_grade_option_id: number | null;
    default_checklist_grade_option_id: number | null;
    incident_interface_id: number | null;
    infrastructure_interface_ids: number[];
    checklist_step_tags: unknown[];
    checklist_grade_options: ChecklistGradeOption[];
};

export type ChecklistGradeOption = {
    id: number;
    name: string;
    grade: number;
    incident_interface_id: number | null;
    pictures: string;
    documents: string;
    comments: string;
    default_tag_for_incident_id: number | null;
};
