import { createContext, createEffect, onMount, ParentProps } from "solid-js";
import { AuthenticatedUser, UseAuthReturn } from "./useAuthReturn";
import {
    createAsyncState,
    useRequiredContext,
    useRunWithErrorBoundary,
    useWindowEventListener,
} from "../../utils/solidjs";
import "./initializeFirebaseApp";
import { useNavigate } from "@solidjs/router";
import {
    AuthStateChangeListener,
    FirebaseAuthentication,
    User as FirebaseUser,
} from "@capacitor-firebase/authentication";
import { createModalController } from "../ui/Modal";
import SwitchWorkspaceModal from "./SwitchWorkspaceModal";
import { useLocale } from "../i18n/context";
import { fireAndForget } from "../../utils/async";

import { BackendVersion } from "../../api/services/auth/interface";
import { getApiInstance } from "../../api";

import { UnauthorizedError } from "../../api/utils";

declare global {
    interface WindowEventMap {
        SignIn: CustomEvent<{ user: AuthenticatedUser }>;
        SignOut: CustomEvent;
    }
}

export function dispatchSignInEvent(user: AuthenticatedUser): void {
    window.dispatchEvent(new CustomEvent("SignIn", { detail: { user } }));
}

export function dispatchSignOutEvent(): void {
    window.dispatchEvent(new CustomEvent("SignOut"));
}

/** True if the user is signed in
 *
 * @remarks
 * This is not reactive. See {@link useAuth} for a reactive version.
 */
export function isAuthenticated(): boolean {
    if (authenticatedUser === undefined) throw new Error(AUTH_STILL_LOADING);
    return authenticatedUser !== null;
}

/** Returns the current user. Throws if not signed in.
 *
 * @throws UnauthorizedError
 *
 * @remarks
 * This is not reactive. See {@link useAuth} for a reactive version.
 */
export function getUserOr401(): AuthenticatedUser {
    if (authenticatedUser === undefined) throw new Error(AUTH_STILL_LOADING);
    if (authenticatedUser === null) throw new UnauthorizedError();
    return authenticatedUser;
}

/** The user that is currently signed in.
 *  - `null`: we know the user is not signed in.
 *  - `undefined`: we don't know yet (authenticator still loading).
 */
let authenticatedUser: AuthenticatedUser | null | undefined;
const AUTH_STILL_LOADING = "We don't know yet if the user is signed in, auth is still loading.";

const AuthContext = createContext<UseAuthReturn>();

export function AuthProvider(props: ParentProps) {
    useFirebaseInAppLanguage();

    const [user, setReactiveUser] = createAsyncState<AuthenticatedUser | null>();
    const setUser = (u: AuthenticatedUser | null) => {
        authenticatedUser = u;
        setReactiveUser(u);
        setAuthenticated(!!u);
    };
    // `createResource(user, user => !!user)` doesn't work here, see https://github.com/solidjs/solid/issues/1864
    const [isAuthenticated, setAuthenticated] = createAsyncState<boolean>();

    useAuthStateChangeListener(({ user: firebaseUser }) => {
        if (firebaseUser) dispatchSignInEvent(adaptUser(firebaseUser));
        else dispatchSignOutEvent();
    });

    const navigate = useNavigate();

    useWindowEventListener("SignIn", (event: CustomEvent<{ user: AuthenticatedUser }>) => {
        setUser(event.detail.user);
        if (
            location.href.endsWith("/sign-in") ||
            location.href.includes("/from-invite") ||
            location.href.includes("/from-magic-link")
        ) {
            navigate("/", { replace: true });
        }
    });

    useWindowEventListener("SignOut", () => {
        setUser(null);
    });

    const switchWorkspaceModal = createModalController<AuthenticatedUser, void>();

    const [locale] = useLocale();

    async function signOut(options: { initiatedByUser: boolean }): Promise<void> {
        const api = getApiInstance();
        await api.auth.signOut();

        navigate("/login");
        const estimatedNavigateDelay = 200;
        if (!options.initiatedByUser)
            setTimeout(() => alert(locale().auth.sessionExpired), estimatedNavigateDelay);
    }

    const auth: UseAuthReturn = {
        isAuthenticated,
        user,
        openLoginPage: async () => navigate("/sign-in", { replace: true }),
        logout: async () => signOut({ initiatedByUser: true }),
        openWorkspaceSwitcher() {
            const u = user();
            if (!u) throw new Error("User must be signed in to switch workspace");
            switchWorkspaceModal.open(u);
        },
    };

    return (
        <AuthContext.Provider value={auth}>
            {props.children}
            <SwitchWorkspaceModal controller={switchWorkspaceModal} />
        </AuthContext.Provider>
    );
}

export const useAuth = () => useRequiredContext(AuthContext, "useAuth", "AuthProvider");

function useFirebaseInAppLanguage(): void {
    const [, { languageCode }] = useLocale();

    createEffect(() => {
        fireAndForget(FirebaseAuthentication.setLanguageCode({ languageCode: languageCode() }));
    });
}

function useAuthStateChangeListener(listenerFunc: AuthStateChangeListener): void {
    const runWithErrorBoundary = useRunWithErrorBoundary();

    onMount(() => {
        FirebaseAuthentication.addListener("authStateChange", change => {
            runWithErrorBoundary(() => {
                listenerFunc(change);
            });
        });
    });
}

export function adaptUser(firebaseUser: FirebaseUser): AuthenticatedUser {
    if (!firebaseUser.email) throw new Error("Firebase user has no email");
    return {
        ...firebaseUser, // keep original object for debugging
        id: firebaseUser.uid,
        email: firebaseUser.email,
        name: firebaseUser.displayName ?? firebaseUser.email,
        firebaseTenantId: firebaseUser.tenantId,
        picture: firebaseUser.photoUrl,
        backendVersion: BackendVersion.Atlas,
    };
}
