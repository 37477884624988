import { createApiMutation, queryClient } from "../../utils";
import { ExecuteWorkflowParams, PerformWorkflowActionParams, SetBpmnParams } from "./interface";
import { InputUnion } from "../formbuilder/interfaces/inputs/BaseInput";

export function createWorkflowMutation() {
    return createApiMutation(api => ({
        mutationKey: ["createWorkflow"],
        mutationFn: (name: string) => api.workflow.createWorkflow(name),
        // onMutate: async (name: string) => {
        //     await queryClient.cancelQueries({ queryKey: ["workflowList"]});
        //     const prevWorkflowList = queryClient.getQueryData<Workflow[]>(["workflowList"]);
        //     queryClient.setQueryData(["workflowList"], (old: Workflow[]) => [...old, {
        //         id: Math.random().toString(),
        //         isActive: false,
        //         name,
        //         bpmn: null,
        //     }]);
        //     return { prevWorkflowList };
        // },
        // onError: (_err, _id, context) => {
        //     queryClient.setQueryData(["workflowList"], context!.prevWorkflowList);
        // },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["workflowList"] });
        },
    }));
}

export function createWorkflowFormMutation() {
    return createApiMutation(api => ({
        mutationKey: ["createForm"],
        mutationFn: (params: {
            fields: InputUnion[];
            processVars: Record<string, string>;
            workflowId: string;
        }) => api.workflow.createWorkflowForm(params),
    }));
}

export function createSetBpmnMutation() {
    return createApiMutation(api => ({
        mutationKey: ["setBpmn"],
        mutationFn: (params: SetBpmnParams) => api.workflow.setBpmn(params),
        onSettled: (_data, _error, params: SetBpmnParams) => {
            queryClient.invalidateQueries({ queryKey: ["workflowList"] });
            queryClient.invalidateQueries({ queryKey: ["workflowDetail"] });
            queryClient.invalidateQueries({
                queryKey: ["formByBpmnElement", { workflowId: params.workflowId }],
            });
        },
    }));
}

export function createActivateWorkflowMutation() {
    return createApiMutation(api => ({
        mutationKey: ["activateWorkflow"],
        mutationFn: (workflowId: string) => api.workflow.activateWorkflow(workflowId),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["workflowList"] });
            queryClient.invalidateQueries({ queryKey: ["workflowDetail"] });
        },
    }));
}

export function createExecuteWorkflowMutation() {
    return createApiMutation(api => ({
        mutationKey: ["executeWorkflow"],
        mutationFn: (params: ExecuteWorkflowParams) => api.workflow.executeWorkflow(params),
    }));
}

export function createUpdateWorkflowFormMutation() {
    return createApiMutation(api => ({
        mutationKey: ["updateWorkflowForm"],
        mutationFn: (params: {
            formId: string;
            fields: InputUnion[];
            processVars: Record<string, string>;
        }) => api.workflow.updateWorkflowForm(params),
    }));
}

export function createPerformWorkflowActionMutation() {
    return createApiMutation(api => ({
        mutationKey: ["performWorkflowAction"],
        mutationFn: (params: PerformWorkflowActionParams) =>
            api.workflow.performWorkflowAction(params),
        onSettled: (_data, _error, params: PerformWorkflowActionParams) => {
            queryClient.invalidateQueries({ queryKey: ["workflowExecutionList"] });
            queryClient.invalidateQueries({ queryKey: ["workflowExecutionDetail"] });
            queryClient.invalidateQueries({ queryKey: ["workflowElementList"] });
            queryClient.invalidateQueries({ queryKey: ["formResponse", params.executionId] });
        },
    }));
}
