import { PageWrapper } from "../ui/pageWrappers";
import { createChecklistListQuery } from "../../api/services/checklist/queries";
import { For, Show } from "solid-js";
import { Button } from "../ui/components";
import { useNavigate } from "@solidjs/router";
import { Checklist } from "../../api/services/checklist/interface";
import { BorderedCard } from "../ui/cards";
import { P } from "../../utils/typography";
import { defaultFormattedDate } from "../../utils/formatDate";

export function ChecklistsPage() {
    const navigate = useNavigate();
    const listChecklist = createChecklistListQuery();

    return (
        <PageWrapper>
            <div class={"flex justify-end gap-x-3"}>
                <Button bgStyle={"outline"} onClick={() => navigate("/checklists/executions")}>
                    Ver ejecuciones
                </Button>
                <Button onClick={() => navigate("/checklists/create")}>Crear Checklist</Button>
            </div>
            <div class="container">
                <div class={"mt-12 grid grid-cols-12 gap-3"}>
                    <Show when={listChecklist.data}>
                        {data => (
                            <For each={data()}>
                                {checklist => <ChecklistItem checklist={checklist} />}
                            </For>
                        )}
                    </Show>
                </div>
            </div>
        </PageWrapper>
    );
}

function ChecklistItem(props: { checklist: Checklist }) {
    const navigate = useNavigate();

    return (
        <BorderedCard
            class={`col-span-12 border-l-4 md:col-span-4`}
            style={{ "border-left-color": props.checklist.color }}
            onClick={() => navigate(`/checklists/create/${props.checklist.id}`)}
        >
            <div class={"mb-3 flex items-start justify-between gap-x-3"}>
                <P class={"!mb-0 font-medium"}>{props.checklist.name}</P>
                <div class="space-y-2">
                    <Show when={props.checklist.is_active}>
                        <Button
                            bgStyle={"outline"}
                            size={"sm"}
                            onClick={e => {
                                e.stopPropagation();
                                navigate(`/checklists/${props.checklist.id}/execute`);
                            }}
                            icon="fas fa-play"
                        >
                            Ejecutar
                        </Button>
                    </Show>
                </div>
            </div>
            <Show when={props.checklist.created_at}>
                {created_at => (
                    <P class={"!mb-0"}>
                        <strong>Creado el:</strong> {defaultFormattedDate(created_at())}
                    </P>
                )}
            </Show>
            <Show when={props.checklist.updated_at}>
                {updated_at => (
                    <P>
                        <strong>Última actualización:</strong> {defaultFormattedDate(updated_at())}
                    </P>
                )}
            </Show>
            <P class={"font-medium"}>
                Checklist {props.checklist.is_active ? "publicado" : "borrador"}
            </P>
        </BorderedCard>
    );
}
