import { createSignal, For, Show, Suspense } from "solid-js";
import ActivityHeader from "./ActivityHeader";
import { range } from "../../utils/miniLodash";
import { Badge, Button } from "../ui/components";
import { useLocale } from "../i18n/context";
import { createUserActivitiesSliceQuery } from "../../api/services/task-manager/queries";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { P } from "../../utils/typography";
import { Activity } from "../../api/services/task-manager/interface";
import { ActivityIcon, Countdown } from "./ActivityItemMobile";
import { getHmsDurationBetween } from "../../utils/formatDate";
import { useNavigate } from "@solidjs/router";
import { useClock } from "../../utils/clock";

export default function AgendaPage() {
    const agenda = useAgenda();

    return (
        <div class="relative min-h-screen bg-layout">
            <ActivityHeader />
            <AgendaHeader />
            <div>
                <Button bgStyle="text-only" onClick={agenda.prependPast}>
                    SeePast
                </Button>
                <For each={agenda.renderedDays()}>
                    {day => (
                        <div class="mb-3 flex gap-3 px-2">
                            <WeekDayAndNumber day={day} />
                            <div class="flex-1">
                                <DayActivities day={day} />
                            </div>
                        </div>
                    )}
                </For>
                <Button bgStyle="text-only" onClick={agenda.appendFuture}>
                    SeeFuture
                </Button>
            </div>
        </div>
    );
}

function useAgenda() {
    const today = Temporal.Now.plainDateISO();
    const chunkLength = 10;
    const [renderedDays, setRenderedDays] = createSignal(
        range(0, chunkLength).map(n => today.add({ days: n })),
    );
    const prependPast = () =>
        setRenderedDays(prev => [
            ...range(chunkLength, 0, -1).map(n => prev[0].subtract({ days: n })),
            ...prev,
        ]);
    const appendFuture = () =>
        setRenderedDays(prev => [
            ...prev,
            ...range(1, chunkLength + 1).map(n => prev[prev.length - 1].add({ days: n })),
        ]);
    return { renderedDays, prependPast, appendFuture };
}

function AgendaHeader() {
    const [locale] = useLocale();
    const today = Temporal.Now.plainDateISO();

    return (
        <div class="sticky top-0 flex items-center bg-white py-1">
            <div class="flex items-center gap-3">
                <pre>Burger</pre>
                <div>
                    {Intl.DateTimeFormat(locale().codeWithCountry, { month: "long" }).format(today)}
                </div>
            </div>
            <div class="flex-1" />
            <span class="flex items-center gap-3">
                {locale().activities.showAvailable}
                <span class="flex h-12 w-12 items-center justify-center rounded-full bg-layout font-semibold text-light-gray-700">
                    12
                </span>
            </span>
        </div>
    );
}

function WeekDayAndNumber(props: { day: Temporal.PlainDate }) {
    const [locale] = useLocale();

    return (
        <div class="flex flex-col items-center">
            <span class="text-sm text-primary-500">
                {Intl.DateTimeFormat(locale().codeWithCountry, {
                    weekday: "short",
                }).format(props.day)}
            </span>
            <span class="flex h-4 w-4 items-center justify-center rounded-full bg-primary-500 text-white">
                {Intl.DateTimeFormat(locale().codeWithCountry, {
                    day: "numeric",
                }).format(props.day)}
            </span>
        </div>
    );
}

function DayActivities(props: { day: Temporal.PlainDate }) {
    const userActivitiesSliceQuery = createUserActivitiesSliceQuery(() => getWeek(props.day));

    return (
        <div>
            <Suspense fallback={<GenericSuspenseFallback />}>
                <Show when={userActivitiesSliceQuery.data}>
                    {userActivitySlice => (
                        <For
                            each={userActivitySlice().filter(activity =>
                                activity.dueDate.toPlainDate().equals(props.day),
                            )}
                            fallback={<NoActivitiesThisDay />}
                        >
                            {activity => <AgendaActivity activity={activity} />}
                        </For>
                    )}
                </Show>
            </Suspense>
        </div>
    );
}

function AgendaActivity(props: { activity: Activity }) {
    const { nowZdt } = useClock();
    const remainingTime = () => getHmsDurationBetween(nowZdt(), props.activity.dueDate);
    const navigate = useNavigate();

    return (
        <div
            class="mb-3 flex rounded-md border border-light-gray-700 bg-white px-2 py-1"
            role="button"
            onClick={() => navigate(props.activity.href)}
        >
            <div class="flex-1">
                <div>{props.activity.title}</div>
                <div class="flex gap-1">
                    <ActivityIcon priority={props.activity.priority} />
                    <Badge class={"border border-light-gray-200 !bg-light-gray-50"}>
                        {props.activity.group.name}
                    </Badge>
                </div>
            </div>
            <Show when={0 <= remainingTime().hours && remainingTime().hours < 24}>
                <Countdown remainingTime={remainingTime()} priority={props.activity.priority} />
            </Show>
        </div>
    );
}

function NoActivitiesThisDay() {
    const [locale] = useLocale();

    return <P>{locale().activities.noActivitiesThisDay}</P>;
}

function getWeek(date: Temporal.PlainDate): {
    startDate: Temporal.PlainDate;
    endDate: Temporal.PlainDate;
} {
    const currentWeekMonday = date.subtract({ days: date.dayOfWeek - 1 });
    return {
        startDate: currentWeekMonday,
        endDate: currentWeekMonday.add({ days: date.daysInWeek }),
    };
}
