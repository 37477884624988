import { P } from "../../utils/typography";
import ActivityItemMobile from "./ActivityItemMobile";
import { createUserActivitiesSliceQuery } from "../../api/services/task-manager/queries";
import { For, Show, Suspense } from "solid-js";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { useResponsiveBreakpoints } from "../../utils/useResponsiveBreakpoints";
import ActivityItemDesktop from "./ActivityItemDesktop";
import _ from "lodash";
import { formattedDate } from "../../utils/formatDate";

export default function ActivityItemList() {
    const { sm } = useResponsiveBreakpoints();
    const today = Temporal.Now.plainDateISO();
    const userActivitiesSliceQuery = createUserActivitiesSliceQuery(() => ({
        startDate: today,
        endDate: today.add({ months: 1 }),
    }));
    return (
        <div>
            <Suspense fallback={<GenericSuspenseFallback />}>
                <Show when={userActivitiesSliceQuery.data}>
                    {activities => (
                        <div class="grid gap-y-px bg-light-gray-300">
                            <For
                                each={Object.entries(
                                    _.groupBy(activities(), act =>
                                        act.dueDate.toPlainDate().toString(),
                                    ),
                                )}
                            >
                                {([dateStr, dailyActivities]) => (
                                    <>
                                        <div class="flex justify-between bg-white p-3">
                                            <h2 id={dateStr}>{formattedDate(dateStr)}</h2>
                                            <div>Fecha límite</div>
                                        </div>
                                        <For
                                            each={dailyActivities}
                                            fallback={<P>No hay actividades ese día.</P>}
                                        >
                                            {a =>
                                                !sm() ? (
                                                    <ActivityItemMobile activity={a} />
                                                ) : (
                                                    <ActivityItemDesktop activity={a} />
                                                )
                                            }
                                        </For>
                                    </>
                                )}
                            </For>
                        </div>
                    )}
                </Show>
            </Suspense>
        </div>
    );
}
