import { createQuery } from "@tanstack/solid-query";
import { createApiQuery, Json } from "../../utils";
import { Accessor } from "solid-js";
import { workflowClient } from "../../../modules/client/client";
import { string, type } from "superstruct";

// export function createUserActivitiesSliceQuery(
//     params: Accessor<{ startDate: Temporal.PlainDate; endDate: Temporal.PlainDate }>,
// ) {
//     return createApiQuery(api => ({
//         queryKey: ["userActivitiesSlice", params()],
//         queryFn: () =>
//             api.taskManager.sliceUserActivities.fetchJson(params().startDate, params().endDate),
//         select: api.taskManager.sliceUserActivities.select,
//     }));
// }

export function createUserActivitiesSliceQuery(
    params: Accessor<{ startDate: Temporal.PlainDate; endDate: Temporal.PlainDate }>,
) {
    return createApiQuery(api => ({
        queryKey: ["userActivitiesSlice", params()],
        queryFn: () => api.taskManager.retrieveMyCalendar.fetchJson(),
        select: (json: Json) => {
            const calendar = api.taskManager.retrieveMyCalendar.select(json);
            return calendar.getActivities(params().startDate, params().endDate);
        },
    }));
}

export function createCalendarQuery() {
    return createQuery(() => ({
        queryKey: ["calendar"],
        queryFn: () =>
            workflowClient.get("/activities/calendar/").receive(type({ calendar: string() })),
    }));
}

export function createActivityTasksQuery() {
    return createApiQuery(api => ({
        queryKey: ["activityTasks"],
        queryFn: () => api.taskManager.getActivityTasks.fetchJson(),
        select: api.taskManager.getActivityTasks.select,
    }));
}
