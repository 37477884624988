import { useQueryClient } from "@tanstack/solid-query";
import { createApiMutation } from "../../utils";
import { CreatePerson } from "./interface";

export function createNewPersonMutation() {
    const queryClient = useQueryClient();

    return createApiMutation(api => ({
        mutationKey: ["createPerson"],
        mutationFn: (person: CreatePerson) => api.person.createPerson(person),
        onSuccess: data => {
            console.log("SUCCESS MUTATION", data);
            queryClient.refetchQueries({
                queryKey: ["people"],
            });
        },
    }));
}
