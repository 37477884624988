import { checkboxStyling } from "../presentation";
import { LabelAfter } from "./parts/layouts";
import { getCommonInputProps } from "./parts/props";
import { createField } from "../state";
import { FieldProps } from "./parts/types";
import { createUniqueId } from "solid-js";

export default function CheckboxField(props: FieldProps<boolean, HTMLInputElement>) {
    const field = createField(props, false);
    const inputId = createUniqueId();

    return (
        <LabelAfter {...props} field={field} inputId={inputId}>
            <input
                type="checkbox"
                {...getCommonInputProps(inputId, props)}
                {...checkboxStyling}
                checked={field.value}
                onChange={e => field.setValue(e.target.checked)}
            />
        </LabelAfter>
    );
}
